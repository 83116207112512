import _ from 'lodash';
import {Box, Button, Divider, IconButton, Paper, TextField} from '@mui/material';
import DateRangePicker, {defaultPresets} from 'components/Form/DateRangePicker';
import AutoComplete from 'components/Form/AutoComplete';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import {
	Campaign as CampaignIcon,
	Flag as FlagIcon,
	CallSplit as CallSplitIcon,
	Language as LanguageIcon,
	Web as WebIcon,
	Group as GroupIcon,
	Refresh as RefreshIcon,
	Search as SearchIcon,
	Clear as ClearIcon,
	Download as DownloadIcon,
	CompareArrows as CompareArrowsIcon,
	VerticalSplit as VerticalSplitIcon,
} from '@mui/icons-material';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import AsyncAutoComplete from 'components/Form/AsyncAutoComplete';
import {fetchTrafficSources, fetchCampaigns, fetchNetworks, fetchOffers, fetchSites, offerStages} from 'utils/autoComplete'
import { useIsMobile } from 'utils/hooks';
import { DatePicker } from 'components/Form/DatePicker';
import CountriesSelect from 'components/Form/CountriesSelect/CountriesSelect';
import { useWorkspaces } from 'context/WorkspaceContext';

const columnsOptions = [
	{id: 'campaign', name: 'Campaign'},
	{id: 'offer', name: 'Offer'},
	{id: 'trafficSource', name: 'Traffic Source'},
	{id: 'site', name: 'Site'},
	{id: 'network', name: 'Network'},
]

const topGroupingButtons = [
	{name: 'Campaigns', icon: <CampaignIcon />, key: 'campaign'},
	{name: 'Offers', icon: <FlagIcon />, key: 'offer'},
	{name: 'Traffic Sources', icon: <CallSplitIcon />, key: 'trafficSource'},
	{name: 'Sites', icon: <WebIcon />, key: 'site'},
	{name: 'Networks', icon: <GroupIcon />, key: 'network'},
	{name: 'Geos', icon: <LanguageIcon />, key: 'geo'},
]

export interface TrackingFilterProps {
	filter: any,
	setFilter: (vc: any) => void,
	exportToCSV: () => void,
	isExporting: boolean,
	refetch: () => void,
}

function GroupedEntityFilter({filter, setFilter}) {
	const {selectedWorkspaces} = useWorkspaces();

	const groupEntity = filter.filter1Name || filter.groupBy1
	let entityName = '', label = '', getItems = _.noop;
	switch (groupEntity) {
	case 'trafficSource':
		entityName = 'trafficSource';
		label = 'Traffic Source';
		getItems = fetchTrafficSources;
		break;
	case 'campaign':
		entityName = 'campaign';
		label = 'Campaign';
		getItems = fetchCampaigns;
		break;
	case 'offer':
		entityName = 'offer';
		label = 'Offer';
		getItems = fetchOffers;
		break;
	case 'network':
		entityName = 'network';
		label = 'Network';
		getItems = fetchNetworks;
		break;
	case 'site':
		entityName = 'site';
		label = 'Site';
		getItems = fetchSites;
		break;
	}

	return (
		<Box sx={{display: 'flex', width: {xs: '100%', md: 'initial'} }}>
			<AutoComplete
				name="group1Name"
				options={columnsOptions}
				value={filter.filter1Name}
				onChange={(_e, newVal) => setFilter({...filter, filter1Name: newVal, filter1Value: null})}
				size="small"
				disableClearable
				sx={{
					'&:not(.Mui-focused) fieldset': {borderRight: 'none', borderTopRightRadius: 0, borderBottomRightRadius: 0},
				}}
				textFieldProps={{
					sx: {
						width: 140,
						'& .MuiInputBase-root.MuiOutlinedInput-root': {pt: '1px', pb: '2px'},
					},
				}}
			/>
			<AsyncAutoComplete
				entityName={entityName}
				limit={15}
				onChange={(newVal) => setFilter({...filter, filter1Value: newVal})}
				value={filter.filter1Value || []}
				where={{[entityName]: {workspaceId: !!selectedWorkspaces.ids.length ? {op: 'in', value: selectedWorkspaces.ids} : null}}}
				limitTags={0}
				getLimitTagsText={(more) => `${more} Selected`}
				multiple
				getItems={getItems}
				label={label}
				name={`${entityName}Id`}
				size="small"
				sx={{flex: 1}}
				textFieldProps={{
					sx: {
						width: {xs: 'auto', md: 250},
						'& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {pt: '1px', pb: '2px'},
						'& .MuiFormLabel-root': {lineHeight: 1},
						'& fieldset': {borderTopLeftRadius: 0, borderBottomLeftRadius: 0},
					},
				}}
			/>
		</Box>
	)
}
export const TrackingFilter = forwardRef<any, TrackingFilterProps>(({filter, setFilter, refetch, exportToCSV, isExporting}, ref) => {
	const [search, setSearch] = useState(filter.search);
	const isMobile = useIsMobile();

	function handleGroupingChange(newValue: string) {
		// const orderedColumns = columnsOptions.filter(o => newValue.includes(o.id)).map(o => o.id)
		const newFilter = {...filter, groupBy1: newValue, search: ''};
		if (newFilter.sortModel?.[0]?.field && !newValue.includes(newFilter.sortModel[0].field)) {
			newFilter.sortModel = [{field: newValue[0], sort: 'desc'}]
		}
		setFilter(newFilter);
	}

	const debouncedSubmit = useCallback(_.debounce(async(newSearch) => {
		setFilter(filter => {
			if (filter.search === newSearch) {return filter}
			return {...filter, search: newSearch}
		})
	}, 300), [setFilter]);

	useEffect(() => {
		setSearch(filter.search || '')
	}, [filter.search])
	useEffect(() => {debouncedSubmit(search)}, [search, debouncedSubmit])

	return (
		<Paper sx={{display: "flex", gap: 1, flexDirection: 'column', p: 1}} ref={ref}>
			<Box sx={{display: 'flex', gap: 0.5, overflow: 'auto'}}>
				{topGroupingButtons.map(btn => (
					<Button
						key={btn.key}
						size="small"
						variant={btn.key === filter.groupBy1 ? "contained" : "outlined"}
						startIcon={btn.icon}
						onClick={() => handleGroupingChange(btn.key)}
						sx={{textTransform: 'none', '& .MuiButton-icon': {mr: 0.5}, flexShrink: 0}}
						disableElevation
					>
						{btn.name}
					</Button>
				))}
			</Box>
			{!isMobile && <Divider />}
			<Box sx={{display: "flex", gap: 0.5, flexWrap: 'wrap', alignItems: 'flex-start'}}>
				<GroupedEntityFilter filter={filter} setFilter={setFilter} />
				{!isMobile && <Box sx={{flex: 1}} />}
				<CountriesSelect
					noAll
					size="small"
					label="Geo"
					name="geo"
					value={filter.geo || []}
					onChange={(newValue) => {setFilter({...filter, geo: newValue})}}
					disableClearable
					sx={{
						'& .MuiFormLabel-root': {lineHeight: 1},
					}}
					textFieldProps={{
						sx: {
							width: 140,
							'& div.MuiInputBase-root.MuiOutlinedInput-root': {pt: '1px', pb: '2px'},
						},
					}}
					multiple
				/>
				<AutoComplete
					options={offerStages}
					value={filter.offerStages}
					onChange={(_e, newVal) => setFilter({...filter, offerStages: newVal, filter1Value: null})}
					label="Stage"
					sx={{
						'& .MuiFormLabel-root': {lineHeight: 1},
					}}
					textFieldProps={{
						sx: {
							width: 140,
							'& div.MuiInputBase-root.MuiOutlinedInput-root': {pt: '1px', pb: '2px'},
						},
					}}
				/>
				<DatePicker
					name="offerDate"
					label="Offer date"
					onChange={(newValue) => {setFilter({...filter, offerDate: newValue})}}
					value={filter.offerDate}
					size="small"
					textFieldProps={{
						size: 'small', sx: {
							width: 260,
							'& .MuiFormLabel-root': {lineHeight: 1},
						},
						InputProps: {inputProps: {sx: {py: 0.5}}},
					}}
				/>
				<TextField
					variant="outlined"
					value={search}
					onChange={e => {
						setSearch(e.target.value)
					}}
					placeholder="Search…"
					size="small"
					inputProps={{sx: {py: 0.5}}}
					InputProps={{
						sx: {pr: 0.5, pl: 1},
						startAdornment: <SearchIcon fontSize="small" />,
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: filter.search ? 'visible' : 'hidden' }}
								onClick={() => setSearch('')}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						),
					}}
					sx={{
						width: { xs: 1, sm: 'auto' },
						minWidth: 250,
						'& .MuiSvgIcon-root': {
							mr: 0.5,
						},
						'& .MuiInput-underline:before': {
							borderBottom: 1,
							borderColor: 'divider',
						},
					}} />
				<DateRangePicker
					name="dateRange"
					value={filter.dateRange}
					onChange={(newValue) => {setFilter({...filter, dateRange: newValue})}}
					timezoneValue={filter.timezone}
					onTimezoneValueChange={(newValue) => {setFilter({...filter, timezone: newValue})}}
					textFieldProps={{
						size: 'small', sx: {width: 260},
						InputProps: {inputProps: {sx: {py: 0.5}}},
					}}
					datesPresets={defaultPresets}
					withTimezone
				/>
				<TooltipButton
					title="Compare Previous period"
					size="small"
					icon={CompareArrowsIcon}
					iconProps={{sx: {fontSize: 20}}}
					color={filter.comparePrevious ? 'primary' : 'default'}
					onClick={() => setFilter({...filter, comparePrevious: !filter.comparePrevious})}
				/>
				<TooltipButton
					title="Flatten"
					size="small"
					icon={VerticalSplitIcon}
					iconProps={{sx: {fontSize: 20}}}
					color={filter.flatten ? 'primary' : 'default'}
					onClick={() => setFilter({...filter, flatten: !filter.flatten})}
				/>
				<TooltipButton
					title="Export"
					size="small"
					loading={isExporting}
					icon={DownloadIcon}
					iconProps={{sx: {fontSize: 20}}}
					onClick={exportToCSV}
				/>
				<TooltipButton
					title="Refresh"
					size="small"
					icon={RefreshIcon}
					iconProps={{sx: {fontSize: 20}}}
					onClick={refetch}
				/>
			</Box>
		</Paper>
	);
})