import axios from "services/axios";


export async function getNonProfitableLiveOffers({workspaceIds}): Promise<any> {
	const {data} = await axios.post('/alerts/getNonProfitableLiveOffers', {workspaceIds})
	return data.items;
}

export async function getNonActiveLive({workspaceIds}): Promise<any> {
	const {data} = await axios.post('/alerts/getNonActiveLive', {workspaceIds})
	return data.items;
}

export async function getEmptyLiveCampaigns({workspaceIds}): Promise<any> {
	const {data} = await axios.post('/alerts/getEmptyLiveCampaigns', {workspaceIds})
	return data.items;
}

export async function getTestToLiveOffers({workspaceIds}): Promise<any> {
	const {data} = await axios.post('/alerts/getTestToLiveOffers', {workspaceIds})
	return data.items;
}

export async function getInactiveNewCampaigns({workspaceIds}): Promise<any> {
	const {data} = await axios.post('/alerts/getInactiveNewCampaigns', {workspaceIds})
	return data.items;
}

export async function getNoConvActiveOffers({workspaceIds}): Promise<any> {
	const {data} = await axios.post('/alerts/getNoConvActiveOffers', {workspaceIds})
	return data.items;
}
