import _ from 'lodash'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Popover, TextField } from '@mui/material';
import {addDays, differenceInDays, endOfMonth, format as formatDate, startOfDay, startOfMonth, startOfYear, subDays, subMonths} from 'date-fns';
import {
	DateRange as DateRangeIcon,
	KeyboardArrowLeft as KeyboardArrowLeftIcon,
	KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { useIsMobile } from 'utils/hooks';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import { Flex } from 'components/Flex/Flex';
import AutoComplete from './AutoComplete';
import { timezoneOptions } from 'utils/autoComplete';

const today = startOfDay(new Date());
export const defaultPresets = [
	{title: 'Today', dateRange: [today, today]},
	{title: 'Yesterday', dateRange: [subDays(today, 1), subDays(today, 1)]},
	{title: 'Last 3 days', dateRange: [subDays(today, 2), today]},
	{title: 'Last 10 days', dateRange: [subDays(today, 9), today]},
	{title: 'Last 30 days', dateRange: [subDays(today, 29), today]},
	{title: 'This month', dateRange: [startOfMonth(today), today]},
	{title: 'Last month', dateRange: [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))]},
	{title: 'This year', dateRange: [startOfYear(today), today]},
] as {title: string, dateRange: ValidDateRange}[]

function formatValue(value): ValidDateRange {
	if (!value || !value.filter(v => v).length) {return [null, null]}
	return [new Date(value[0]), new Date(value[1])];
}

type ValidDateRange = [Date | null, Date | null];
interface DateRangePickerProps {
	name: string,
	label?: string
	value: ValidDateRange,
	onChange: (value: any) => void,
	textFieldProps?: any,
	datesPresets?: {title: string, dateRange: ValidDateRange}[],
	withTimezone?: boolean,
	timezoneValue?: number,
	onTimezoneValueChange?: (value: number) => void
}
export default function DateRangePicker({name, value = [null, null], withTimezone, timezoneValue, onTimezoneValueChange, onChange, textFieldProps, datesPresets = defaultPresets, label}: DateRangePickerProps) {
	const [internalValue, setInternalValue] = useState(formatValue(value))
	const [popoverOpen, setPopverOpen] = useState(false)
	const anchorRef = useRef();
	const isMobile = useIsMobile();

	useEffect(() => {
		setInternalValue(formatValue(value));
	}, [value])

	function handleSave() {
		if (!internalValue[0] || !internalValue[1]) {return;}
		onChange(internalValue);
		setPopverOpen(false);
	}
	function handleChange(newValue) {
		setInternalValue(newValue);
	}
	function handleClose() {
		setPopverOpen(false);
		setInternalValue(formatValue(value));
	}
	function moveRange(e, direction) {
		e.stopPropagation();
		if (!internalValue[0] || !internalValue[1]) {return;}
		const numDays = differenceInDays(internalValue[1], internalValue[0]) + 1;
		const newRange: ValidDateRange = [
			addDays(internalValue[0], numDays * direction),
			addDays(internalValue[1], numDays * direction),
		];
		onChange(newRange);
	}
	function isRangeMatching(dateRange) {
		const firstDateMatch = (!dateRange[0] && !internalValue[0]) || (dateRange[0] && internalValue[0] && dateRange[0].getTime() === internalValue[0].getTime());
		const secondDateMatch = (!dateRange[1] && !internalValue[1]) || (dateRange[1] && internalValue[1] && dateRange[1].getTime() === internalValue[1].getTime());
		return firstDateMatch && secondDateMatch;
	}
	const inputValue = internalValue.filter(r => r).length ? `${internalValue[0] ? formatDate(new Date(internalValue[0]), "dd/MM/yy") : ''} ➝ ${internalValue[1] ? formatDate(new Date(internalValue[1]), "dd/MM/yy") : ''}` : ''
	const actualPresets = datesPresets ?? defaultPresets;

	return (
		<>
			<TextField
				fullWidth
				{...textFieldProps}
				ref={anchorRef}
				label={label}
				readOnly
				name={name} // should be here?
				focused={popoverOpen}
				value={inputValue}
				InputProps={{
					...textFieldProps.InputProps,
					startAdornment: <DateRangeIcon sx={{mr: 1}} />,
					sx: {pr: 1},
					endAdornment: (
						<Flex gap={0}>
							<TooltipButton
								title="Back"
								size="small"
								icon={KeyboardArrowLeftIcon}
								sx={{p: 0}}
								iconProps={{sx: {fontSize: 18}}}
								onClick={(e) => moveRange(e, -1)}
							/>
							<TooltipButton
								title="Back"
								size="small"
								icon={KeyboardArrowRightIcon}
								sx={{p: 0}}
								iconProps={{sx: {fontSize: 18}}}
								onClick={(e) => moveRange(e, 1)}
							/>
						</Flex>
					),
				}}
				onClick={() => setPopverOpen(true)}
			/>
			<Popover
				open={popoverOpen}
				anchorEl={anchorRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={handleClose}
				disableRestoreFocus
			>
				<Box sx={{p: 2}}>
					<Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
						<StaticDateRangePicker
							displayStaticWrapperAs={isMobile ? 'mobile' : 'desktop'}
							// format="dd/MM/yyyy"
							value={internalValue}
							onChange={handleChange}
							onAccept={() => isMobile && handleSave()}
						/>
						{!isMobile && actualPresets?.length && (
							<Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
								{actualPresets.map((pr, i) => (
									<Button key={i} size="small" variant={isRangeMatching(pr.dateRange) ? 'contained' : 'outlined'} onClick={() => setInternalValue(pr.dateRange)}>{pr.title}</Button>
								))}
							</Box>
						)}
					</Box>
					<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
						{withTimezone && (
							<AutoComplete
								label="Timezone"
								options={timezoneOptions}
								value={timezoneValue || 0}
								onChange={(_e, newVal) => onTimezoneValueChange?.(newVal)}
								size="small"
								textFieldProps={{sx: {width: 260}, size: 'small'}}
							/>
						)}
						{!isMobile && <Box sx={{textAlign: 'end'}}>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSave}
								disabled={!internalValue[0] || !internalValue[1]}
							>
								Apply
							</Button>
						</Box>}
					</Box>
				</Box>
			</Popover>
		</>
	);
}
