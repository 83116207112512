import _ from 'lodash';
import axios from 'services/axios';
import {BulkUpadeOffersPayload, FetchOffersProps, UpsertOfferPayload} from './api.interface';
import queryString from 'query-string';
import { toUTC } from 'utils/transformUtils';

function formatOffer(offer) {
	return {...offer, date: offer.date && new Date(offer.date)};
}
export async function fetchOffers({pagination, filter, sortModel, workspaceIds}: FetchOffersProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {query: {offer: {...filter.offer}, search: filter.text}};

	if (workspaceIds?.length) {
		payload.query.offer.workspaceId = {op: 'in', value: workspaceIds};
	}

	if (filter.dateRange.filter(d => d).length) {
		payload.query.offer.date = {op: 'between', value: filter.dateRange.map(toUTC)};
	}
	const orderBy = sortModel?.length ? getOrderBy(sortModel[0].field) : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/offers/query?${queryStr}`;
	const resp = await axios.post(url, payload);

	return {
		...resp.data,
		items: resp.data.items.map(formatOffer),
	};
}

function getOrderBy(column: string) {
	switch (column) {
	case 'network.name':
		return column;
	default:
		return `offer.${column}`;
	}
}
export async function fetchSingleOffer(id: string) {
	const url = `offers/${id}`;
	const {data} = await axios.get(url);   
	return data;
}

export async function upsertOffer({offer}: UpsertOfferPayload) {
	const method = offer.id ? axios.patch : axios.post;
	const url = offer.id ? `offers/${offer.id}` : `offers`;
	const {data} = await method(url, offer);   
	return formatOffer(data);
}

export async function bulkUpdateOffers({ids, update}: BulkUpadeOffersPayload) {
	
	const chunks = _.chunk(ids, 10)
	for (const chunk of chunks) {
		const promises = chunk.map(id => axios.patch(`offers/${id}`, update))
		await Promise.all(promises);
	} 
}

export async function deleteOffer({id}) {
	const url = `offers/${id}`;
	const {data} = await axios.delete(url);
	return data;
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	case 'nameExists':
		return 'Offer with this name already exists'
	case 'externalIdExists':
		return `Offer with same network-site-geo-externalId combination already exists: ${error?.response?.data.meta.offer.name}`
	default:
		return 'Oops. Request failed';
	}
}
