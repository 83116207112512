import _ from 'lodash';
import {Box, FormControlLabel, Grid, Paper, Popover, Switch} from '@mui/material';
import DateRangePicker, {defaultPresets} from 'components/Form/DateRangePicker';
import CountriesSelect from 'components/Form/CountriesSelect/CountriesSelect';
import AutoComplete from 'components/Form/AutoComplete';
import AsyncAutoComplete from 'components/Form/AsyncAutoComplete';
import { fetchCampaigns, fetchOffers, fetchSites, fetchNetworks, fetchTrafficSources } from 'utils/autoComplete';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import {
	FilterAlt as FilterAltIcon,
	Refresh as RefreshIcon,
} from '@mui/icons-material';
import { useState } from 'react';
import { useWorkspaces } from 'context/WorkspaceContext';
import { Flex } from 'components/Flex/Flex';

const allKinds = ['default', 'advertiserStats'], defaultKind = ['default']

const columnsOptions = [
	{id: 'date', name: 'Date', allowed: allKinds},
	{id: 'offerGeo', name: 'Offer Geo', allowed: allKinds},
	{id: 'campaignGeo', name: 'Campaign Geo', allowed: allKinds},
	{id: 'workspaceName', name: 'Workspace', allowed: defaultKind},
	{id: 'trafficSourceName', name: 'Traffic Source', allowed: defaultKind},
	{id: 'campaignName', name: 'Campaign', allowed: defaultKind},
	{id: 'offerName', name: 'Offer', allowed: defaultKind},
	{id: 'offerStage', name: 'Offer Stage', allowed: defaultKind},
	{id: 'offerUrl', name: 'Offer Url', allowed: defaultKind},
	{id: 'siteName', name: 'Site', allowed: defaultKind},
	{id: 'networkName', name: 'Network', allowed: defaultKind},
	{id: 'roi', name: 'ROI', allowed: defaultKind},
	{id: 'visits', name: 'Visits', allowed: defaultKind},
	{id: 'defaultConversions', name: 'DefaultConversions', allowed: defaultKind},
	{id: 'revenue', name: 'Revenue', allowed: defaultKind},
	{id: 'defaultRevenue', name: 'Gross Revenue', allowed: defaultKind},
	{id: 'cost', name: 'Cost', allowed: defaultKind},
	{id: 'profit', name: 'Profit', allowed: defaultKind},
	{id: 'cvr', name: 'CVR', allowed: defaultKind},
	{id: 'rpc', name: 'RPC', allowed: defaultKind},
	{id: 'mttc', name: 'MTTC', allowed: defaultKind},
	{id: 'rejectionConversions', name: 'RejectionConversions', allowed: defaultKind},
	{id: 'rejectionRevenue', name: 'RejectionRevenue', allowed: defaultKind},
]

const filters = [
	{name: 'trafficSourceId', label: 'Traffic Sources', defaultVal: [], allowed: defaultKind},
	{name: 'campaignId', label: 'Campaigns', defaultVal: [], allowed: defaultKind},
	{name: 'offerId', label: 'Offers', defaultVal: [], allowed: defaultKind},
	{name: 'siteId', label: 'Sites', defaultVal: [], allowed: defaultKind},
	{name: 'networkId', label: 'Networks', defaultVal: [], allowed: defaultKind},
	{name: 'offerGeo', label: 'Offer Geo', defaultVal: [], allowed: allKinds},
	{name: 'campaignGeo', label: 'Campaign Geo', defaultVal: [], allowed: allKinds},
]
const allowedFiltersByKind = allKinds.reduce((aggr, kind) => ({...aggr, [kind]: filters.filter(c => c.allowed?.includes(kind))}), {} as {[key: string]: typeof filters})
const allowedColumnsByKind = allKinds.reduce((aggr, kind) => ({...aggr, [kind]: columnsOptions.filter(c => c.allowed?.includes(kind))}), {} as {[key: string]: typeof columnsOptions})

const entityFilters = [
	{name: 'trafficSource', label: 'Traffic Sources', fetcher: fetchTrafficSources},
	{name: 'campaign', label: 'Campaigns', fetcher: fetchCampaigns},
	{name: 'offer', label: 'Offers', fetcher: fetchOffers},
	{name: 'site', label: 'Sites', fetcher: fetchSites},
	{name: 'network', label: 'Networks', fetcher: fetchNetworks},
]
export default function ReportsFilter({setViewConfig, viewConfig, refetch}) {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const {selectedWorkspaces} = useWorkspaces();

	function handleColumnsChange(e, newValue) {
		const orderedColumns = columnsOptions.filter(o => newValue.includes(o.id)).map(o => o.id)
		const newViewConfig = {...viewConfig, columns: orderedColumns};
		if (newViewConfig.sortModel?.[0]?.field && !newValue.includes(newViewConfig.sortModel[0].field)) {
			newViewConfig.sortModel = [{field: newValue[0], sort: 'desc'}]
		}
		setViewConfig(newViewConfig);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={2}>
				<Paper>
					<DateRangePicker
						name="dateRange"
						value={viewConfig.filter.dateRange}
						label="Date Range"
						onChange={(newValue) => {setViewConfig({...viewConfig, filter: {...viewConfig.filter, dateRange: newValue}})}}
						textFieldProps={{sx: {"& fieldset": { border: 'none' }}, size: 'small'}}
						datesPresets={defaultPresets}
					/>
				</Paper>
			</Grid>
			<Grid item xs={11.5} md={9.5}>
				<Paper>
					<AutoComplete
						label="Columns"
						name="columns"
						options={allowedColumnsByKind[viewConfig.reportKind]}
						value={viewConfig.columns}
						onChange={handleColumnsChange}
						size="small"
						textFieldProps={{sx: {"& fieldset": { border: 'none' }}}}
						multiple
					/>
				</Paper>
			</Grid>
			<Grid item xs={8} md={0.5}>
				<Flex gap={0}>
					<Popover 
						anchorEl={anchorEl}
						open={!!anchorEl}
						onClose={() => setAnchorEl(null)}
					>
						<Box sx={{p: 2, display: 'flex', flexDirection: "column"}}>
							<Box sx={{fontWeight: 500}}>Filters</Box>
							{allowedFiltersByKind[viewConfig.reportKind].map(fItem => (
								<FormControlLabel
									key={fItem.name}
									control={
										<Switch
											name={fItem.name}
											type="checkbox"
											color="primary"
											checked={fItem.name in viewConfig.filter}
											onChange={(e, checked) => {
												if (checked) {
													setViewConfig({...viewConfig, filter: {...viewConfig.filter, [fItem.name]: fItem.defaultVal}})
												} else {
													setViewConfig({...viewConfig, filter: _.omit(viewConfig.filter, [fItem.name])})
												}
											}}
										/>
									}
									label={fItem.label}
								/>
							))}
						</Box>
					</Popover>
					<TooltipButton
						icon={RefreshIcon} title="Refresh"
						onClick={refetch}
					/>
					<TooltipButton
						icon={FilterAltIcon} title="Filters"
						onClick={(e) => {
							setAnchorEl(e.currentTarget);
						}}
					/>
				</Flex>
			</Grid>
			{'geo' in viewConfig.filter && 
				<Grid item xs={12} md={2}>
					<Paper>
						<CountriesSelect
							label="Geo"
							name="geo"
							size="small"
							value={viewConfig.filter.geo}
							onChange={(newValue) => {setViewConfig({...viewConfig, filter: {...viewConfig.filter, geo: newValue}})}}
							textFieldProps={{sx: {"& fieldset": { border: 'none' }}}}
							multiple
						/>
					</Paper>
				</Grid>}
			{entityFilters.map(item => `${item.name}Id` in viewConfig.filter && (
				<Grid key={item.name} item xs={12} md={2}>
					<Paper>
						<AsyncAutoComplete
							entityName={item.name}
							limit={15}
							getItems={item.fetcher}
							value={viewConfig.filter[`${item.name}Id`]}
							where={{[item.name]: {workspaceId: !!selectedWorkspaces.ids.length ? {op: 'in', value: selectedWorkspaces.ids} : null}}}
							onChange={(newValue) => {setViewConfig({...viewConfig, filter: {...viewConfig.filter, [`${item.name}Id`]: newValue}})}}
							textFieldProps={{sx: {"& fieldset": { border: 'none' }}, size: 'small'}}
							size='small'
							label={item.label}
							name={`${item.name}Id`}
							sx={{flex: 1}}
							multiple
						/>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
}
