import { useState, useEffect } from "react";
import { Drawer as MuiDrawer, List, Box } from '@mui/material';
import {
	AutoGraph as AutoGraphIcon,
	Campaign as CampaignIcon,
	LocalOffer as LocalOfferIcon,
	Language as LanguageIcon,
	CallSplit as CallSplitIcon,
	Workspaces as WorkspacesIcon,
	Bookmark as BookmarkIcon,
	AccessTime as AccessTimeIcon,
	WarningAmber as WarningAmberIcon,
	CurrencyExchange as CurrencyExchangeIcon,
} from "@mui/icons-material";
import { useTheme, styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import config from 'config/config';

// styles
import styles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
	useLayoutState,
	drawerWidth,
	useLayoutDispatch,
	toggleSidebar,
} from "../../context/LayoutContext";
import { useHeaderHeight } from "utils/hooks";
import { StaleAppWarning } from "components/StaleAppWarning/StaleAppWarning";
import AsyncAutoComplete from "components/Form/AsyncAutoComplete";
import { fetchWorkspaces } from "utils/autoComplete";
import { useWorkspaces } from "context/WorkspaceContext";

const adminRoles = ['superAdmin']
const structure = [
	{ label: "Dashboard", link: "/app/admin/dashboard", icon: <AutoGraphIcon />, roles: adminRoles},
	{ label: "Alerts", link: "/app/admin/alerts", icon: <WarningAmberIcon />, roles: adminRoles},
	{ label: "Performance", link: "/app/admin/performance", icon: <AccessTimeIcon />, roles: adminRoles},
	{ label: "Reports", link: "/app/admin/reports", icon: <AccessTimeIcon />, roles: adminRoles},
	{ label: "Conversions", link: "/app/admin/conversions", icon: <CurrencyExchangeIcon />, roles: adminRoles},
	{ label: "Traffic Sources", link: "/app/admin/trafficSources", icon: <CallSplitIcon />, roles: adminRoles},
	{ label: "Campaigns", link: "/app/admin/campaigns", icon: <CampaignIcon />},
	{ label: "Offers", link: "/app/admin/offers", icon: <LocalOfferIcon />},
	{ label: "Sites", link: "/app/admin/sites", icon: <LanguageIcon />, roles: adminRoles},
	{ label: "Networks", link: "/app/admin/networks", icon: <CampaignIcon />, roles: adminRoles},
	{ label: "Workspaces", link: "/app/admin/workspaces", icon: <WorkspacesIcon />, roles: adminRoles},
	{ label: "Tags", link: "/app/admin/tags", icon: <BookmarkIcon />, roles: adminRoles},
];

const Drawer = styled(MuiDrawer)(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9),
				},
			}),
		},
	}),
);

function Sidebar({ user }) {
	var theme = useTheme();
	const location = useLocation();
	const {selectedWorkspaces, setSelectedWorkspaces} = useWorkspaces();
	
	// global
	var { isSidebarOpened } = useLayoutState();

	// local
	var [isPermanent, setPermanent] = useState(true);
	const layoutDispatch = useLayoutDispatch();
	const {headerHeight, nonHeaderHeight} = useHeaderHeight();

	useEffect(function() {
		window.addEventListener("resize", handleWindowWidthChange);
		handleWindowWidthChange();
		return function cleanup() {
			window.removeEventListener("resize", handleWindowWidthChange);
		};
	});

	useEffect(() => {
		if (!isPermanent) {
			toggleSidebar(layoutDispatch, false);
		}
	}, [location.key, isPermanent, layoutDispatch]);

	function handleClose() {
		toggleSidebar(layoutDispatch, false);
	}
	const {primaryRole} = user || {};
	return (
		<Drawer
			variant={isPermanent ? "permanent" : "temporary"}
			sx={{
				...styles.drawer,
				height: nonHeaderHeight,
				mt: isPermanent ? 0 : `${headerHeight}px`,
			}}
			open={isSidebarOpened}
			onClose={handleClose}
			anchor="left"
		>
			<Box sx={{padding: '30px 10px', textAlign: 'center'}}>
				{config.flavorConfig.logo && <img src={config.flavorConfig.logo} alt="logo" style={{
					width: '100%',
					maxWidth: config.flavorConfig.logoMaxWidth,
				}} />}
			</Box>
			{isSidebarOpened && (
				<AsyncAutoComplete
					entityName='workspace'
					limit={15}
					getItems={fetchWorkspaces}
					onChange={(id) => setSelectedWorkspaces({ids: [id].filter(f => f)})}
					value={selectedWorkspaces?.ids[0] || null}
					label="Workspace"
					name="workspaceId"
					sx={{mx: 1}}
					size="small"
				/>
			)}
			<List sx={{
				overflow: 'auto',
			}}>
				{structure.filter(s => !s.hide && (!s.roles || s.roles.includes(primaryRole))).map((link, i) => (
					<SidebarLink
						key={link.id || i}
						location={location}
						isSidebarOpened={isSidebarOpened}
						{...link}
					/>
				))}
			</List>
			<StaleAppWarning iconOnly={!isSidebarOpened} />
		</Drawer>
	);

	// ##################################################################
	function handleWindowWidthChange() {
		var windowWidth = window.innerWidth;
		var breakpointWidth = theme.breakpoints.values.md;
		var isSmallScreen = windowWidth < breakpointWidth;

		if (isSmallScreen && isPermanent) {
			setPermanent(false);
		} else if (!isSmallScreen && !isPermanent) {
			setPermanent(true);
		}
	}
}

export default Sidebar;
