import { fetchTrafficHourlyPerformance } from "modules/trafficHourlyPerformance/api";
import { useWrappedQuery } from "utils/reactQueryHooks";
import {endOfDay, startOfMonth, subDays} from 'date-fns';
import _ from 'lodash'
import { Box, Paper } from "@mui/material";
import { Flex } from "components/Flex/Flex";
import { formatNum } from "utils/transformUtils";
import {GridSortModel} from '@mui/x-data-grid-pro';

const columns = ['workspace', 'defaultRevenue', 'cost'];
const now = new Date();
const date = [startOfMonth(now), endOfDay(subDays(now, 1))]
const numDays = date[1].getDate();

const sortModel: GridSortModel = [{field: 'profit', sort: 'desc'}]
export function MonthlyProfitPanel({filter}) {

	const {isLoading, data: statsResp} = useWrappedQuery({
		queryKey: ['MonthlyProfitPanel', filter.workspaces],
		queryFn: () => fetchTrafficHourlyPerformance({
			columns,
			sortModel,
			filter: {
				dateRange: date,
			},
		}),
	});
	
	return (
		<Flex justify="flex-start" sx={{flexWrap: 'wrap'}}>
			{statsResp?.items?.map(stat => {
				const profit = (stat?.defaultRevenue || 0) * 0.6 - (stat?.cost || 0);
				const roi = stat?.cost ? (profit / stat?.cost * 100) : 0;
				const avgProfit = profit / numDays
				return (
					<Paper key={stat.workspace.id} sx={{px: 2, py: 1, display: 'inline-block'}}>
						<Box component="div" sx={{color: stat.workspace.color, fontWeight: 'bold', mr: 1, fontSize: 20, display: 'inline-block', width: 50}}>{stat.workspace.name}</Box>
						<Box component="span" sx={{fontWeight: 'bold'}}>
							${formatNum(profit, 0)}
						</Box>
						<Box component="span" sx={{fontSize: 12, ml: 0.5}}>(${formatNum(avgProfit, 0)} avg, {formatNum(roi, 0)}% roi)</Box>
					</Paper>
				)
			})}
		</Flex>
	)
}