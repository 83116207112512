import { Paper, Box, Typography } from "@mui/material"
import {
	Download as DownloadIcon,
} from "@mui/icons-material";
import {LoadingButton} from '@mui/lab';
import { useWrappedQuery } from "utils/reactQueryHooks";
import { GridSortModel } from "@mui/x-data-grid-pro";
import PlaygroundFilter from "./PlaygroundFilter";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { formatNum, formatDate, toUTC } from "utils/transformUtils";
import fileSaver from 'file-saver';
import { createCSV } from 'utils/fileUtils';
import { fetchTrafficHourlyPerformance } from "modules/trafficHourlyPerformance/api";
import { EnhancedDataGrid } from "components/DataGrid/EnhancedDataGrid";
import config from "config/config";
import { useAvailableWindowHeight } from "utils/hooks";
import { CountryFlag } from "components/CountryFlag/CountryFlag";
import { useWorkspaces } from "context/WorkspaceContext";

const getNumberFormatter = (decimal = 2) => ({
	valueFormatter: (value) => Number.isFinite(value) ? formatNum(value, decimal) : value,
})

const allKinds = ['default', 'advertiserStats'], defaultKind = ['default']
const columnsMap = {
	date: { field: 'date', headerName: 'Date', minWidth: 80, valueFormatter: (value) => value?.slice(0, 10), defaultFor: allKinds},
	offerGeo: { field: 'offerGeo', headerName: 'Offer Geo', width: 80, minWidth: 80, defaultFor: allKinds, renderCell: ({value}) => value && (<CountryFlag country={value} />)},
	campaignGeo: { field: 'campaignGeo', headerName: 'Cmp Geo', width: 80, minWidth: 80, renderCell: ({value}) => value && (<CountryFlag country={value} />)},
	workspaceName: { field: 'workspaceName', headerName: 'Workspace', minWidth: 150, flex: 1, valueFormatter: (value) => value?.name},
	trafficSourceName: { field: 'trafficSourceName', headerName: 'Traffic Source', minWidth: 150, flex: 1, valueFormatter: (value) => value?.name},
	campaignName: { field: 'campaignName', headerName: 'Campaign', minWidth: 150, flex: 1, valueFormatter: (value) => value?.name, defaultFor: defaultKind},
	offerName: { field: 'offerName', headerName: 'Offer', minWidth: 150, flex: 1, defaultFor: defaultKind},
	offerStage: { field: 'offerStage', headerName: 'Stage', minWidth: 80, width: 80},
	offerUrl: { field: 'offerUrl', headerName: 'Url', minWidth: 80, width: 80},
	siteName: { field: 'siteName', headerName: 'Site', minWidth: 150, flex: 1, valueFormatter: (value) => value?.name},
	networkName: { field: 'networkName', headerName: 'Network', minWidth: 150, flex: 1, valueFormatter: (value) => value?.name},
	roi: { field: 'roi', headerName: 'ROI', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},
	visits: { field: 'visits', headerName: 'Visits', width: 80, ...getNumberFormatter(0), defaultFor: allKinds},
	defaultConversions: { field: 'defaultConversions', headerName: 'Conversions', width: 80, ...getNumberFormatter(0), defaultFor: allKinds},
	revenue: { field: 'revenue', headerName: 'Revenue', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},
	defaultRevenue: { field: 'defaultRevenue', headerName: 'Gross Revenue', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},
	cost: { field: 'cost', headerName: 'Cost', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},
	profit: { field: 'profit', headerName: 'Profit', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},
	cvr: { field: 'cvr', headerName: 'CVR', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},
	rpc: { field: 'rpc', headerName: 'RPC', width: 80, ...getNumberFormatter(2)},
	mttc: { field: 'mttc', headerName: 'MTTC', width: 80, valueFormatter: (value) => value && formatNum(value, 0)},
	rejectionConversions: { field: 'rejectionConversions', headerName: 'Rejection Conversions', width: 80, ...getNumberFormatter(0)},
	rejectionRevenue: { field: 'rejectionRevenue', headerName: 'Rejection Revenue', width: 80, ...getNumberFormatter(2), defaultFor: allKinds},

}
const columnsOrder = Object.keys(columnsMap);
const defaultColumnsByKind = allKinds.reduce((aggr, kind) => ({...aggr, [kind]: columnsOrder.filter(c => columnsMap[c].defaultFor?.includes(kind))}), {} as {[key: string]: string[]})

const now = new Date();

function fetchDataByReportKind(viewConfig) {
	switch (viewConfig.reportKind) {
	case 'default':
	default:
		return fetchTrafficHourlyPerformance(viewConfig)
	}
}

function mapItemForDownload(itm) {
	const updatedItm = {...itm};
	if (itm.date) {
		updatedItm.date = itm.date.slice(0, 10)
	}
	return updatedItm;
}

export function PlaygroundPage({isActive}) {
	const [downloadingStats, setDownloadingStats] = useState(false);
	const gridHeight = useAvailableWindowHeight()
	const {selectedWorkspaces: {ids: wsIds}} = useWorkspaces();

	const [viewConfig, setViewConfig] = useState({
		sortModel: [{field: 'date', sort: 'desc'}] as GridSortModel,
		pagination: {page: 0, pageSize: config.paginationDefaultValue},
		columns: defaultColumnsByKind.default,
		filter: {dateRange: [toUTC(subDays(now, 7)).slice(0, 10), toUTC(subDays(now, 2)).slice(0, 10)], workspaceId: [] as string[]},
		reportKind: 'default',
	});

	useEffect(() => {setViewConfig(viewConfig => ({...viewConfig, filter: {...viewConfig.filter, workspaceId: wsIds}}))}, [wsIds])

	const {isLoading, data: stats, refetch} = useWrappedQuery({
		queryKey: [viewConfig, 'stats'],
		queryFn: () => fetchDataByReportKind(viewConfig),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});

	async function downloadStats() {
		try {
			setDownloadingStats(true);
			const statsResp = await fetchDataByReportKind({...viewConfig, pagination: {page: 0, pageSize: -1}})
			const columns = viewConfig.columns.reduce((aggr, key) => {
				aggr[key] = columnsMap[key].headerName || key;
				return aggr;
			}, {})
			const mappedStats = statsResp.items.map(i => mapItemForDownload(i))
			const csv = await createCSV(mappedStats, {columns});
			fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `report-${formatDate(viewConfig.filter.dateRange[0], 'yyyyMMdd')}-${formatDate(viewConfig.filter.dateRange[1], 'yyyyMMdd')}.csv`);

		} catch (err) {
			console.error(err);
		} finally {
			setDownloadingStats(false);
		}
	}

	return (
		<>
			<PlaygroundFilter
				setViewConfig={setViewConfig}
				viewConfig={viewConfig}
				refetch={refetch}
			/>
			<Paper sx={{p: 2, mt: 2, display: 'flex', flexDirection: 'column', gap: 2, height: gridHeight}}>
				<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
					<Typography variant="h2">Stats</Typography>
					<LoadingButton
						loading={downloadingStats}
						variant="contained"
						startIcon={<DownloadIcon />}
						loadingPosition="start"
						onClick={downloadStats}
					>
						Download
					</LoadingButton>
				</Box>
				<EnhancedDataGrid
					noToolbar
					loading={isLoading}
					items={stats?.items}
					rowHeight={32}
					getRowId={row => row.id || row.rank}
					pinnedRows={{bottom: [{id: '_totals', ...stats?._meta?.totals}]}}
					pluralName="Stats"
					disableColumnMenu
					pagination
					paginationMode="server"
					rowCount={stats?._meta.pagination.totalItems || 0}
					columns={columnsOrder.filter(co => viewConfig.columns.includes(co)).map(c => columnsMap[c])}
					paginationModel={viewConfig.pagination}
					onPaginationModelChange={(newPagination) => setViewConfig({...viewConfig, pagination: newPagination})}
					sortingMode="server"
					sortModel={viewConfig.sortModel}
					onSortModelChange={sortModel => setViewConfig({...viewConfig, sortModel, pagination: {...viewConfig.pagination, page: 0}})}
				/>
			</Paper>
		</>
	);
}
