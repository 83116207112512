import React from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import store, {history} from './redux/store'
import './index.css';
import App from 'components/App';
import reportWebVitals from './reportWebVitals';
import { LayoutProvider } from "./context/LayoutContext";
import Themes from "./themes";
import { HistoryRouter } from "redux-first-history/rr6";
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'modules/notify/snackBarUtils';
import { HelmetProvider } from 'react-helmet-async';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import config from 'config/config';
import queryClient from './services/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { WorkspacesProvider } from 'context/WorkspaceContext';
import './services/chart';

LicenseInfo.setLicenseKey(config.muiLicenseKey);

ReactDOM.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<LayoutProvider>
				<HelmetProvider>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={Themes.default}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<CssBaseline />
								<SnackbarProvider maxSnack={3}>
									<SnackbarUtilsConfigurator />
									<HistoryRouter history={history}>
										<QueryClientProvider client={queryClient}>
											<WorkspacesProvider>
												<App />
											</WorkspacesProvider>
										</QueryClientProvider>
									</HistoryRouter>
								</SnackbarProvider>
							</LocalizationProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</HelmetProvider>
			</LayoutProvider>
		</ReduxProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
