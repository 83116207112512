import {connect} from 'utils/reduxUtils';
import {BirthdayLogin} from './customLogins/birthday';
import Login from './Login';
import * as AuthSelectors from 'modules/auth/selectors';
import {Actions} from 'pages/actions';

const todayDate = new Date().toISOString()
const todaysDates = [todayDate.slice(5, 10), todayDate.slice(0, 10)]

const todaysEvents = [
	{name: 'Roy', date: '12-19'},
	{name: 'Alon', date: '10-12'},
	{name: 'Guy', date: '12-03'},
	{name: 'Roi', date: '07-22'},
	{name: 'Yuval', date: '06-25'},
	{name: 'Sagi', date: '04-16'},
	{name: 'Vlada', date: '06-13'},
	{name: 'Gabriela', date: '03-16'},
	{name: 'Leah', date: '09-19'},
	{name: 'Keren', date: '09-03'},
].filter(r => todaysDates.includes(r.date));

const birthdayName = window.location.search.match(/birthday=(\w+)/)?.[1]
if (birthdayName) {
	todaysEvents.push({name: birthdayName})
}

const Component = todaysEvents.length ? BirthdayLogin : Login;

const mapDispatchToProps = {
	onSubmit: Actions.LOGIN_FORM_SUBMITTED,
};

const mapStateToProps = state => {
	return {
		loading: AuthSelectors.isLoading(state),
		error: AuthSelectors.loginFailureMessage(state),
		birthdays: todaysEvents,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
