import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {
	VisibilityOff as VisibilityOffIcon,
	Visibility as VisibilityIcon,
} from '@mui/icons-material';
import TooltipButton from 'components/TooltipButton/TooltipButton';

function NonSelectionToolBar({noSearch, searchValue, onSearchChange, searchAutoFocus = true, clearSearch, filters, sx, hiddenToggle, actions}) {
	return (
		<Box
			sx={{
				p: t => ({
					xs: 1,
					md: t.spacing(1, 2, 1),
				}),
				justifyContent: 'space-between',
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				...sx,
			}}
		>
			<Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1, flexWrap: { xs: 'wrap', md: 'nowrap' }, justifyContent: 'center' }}>
				{!noSearch && <TextField
					variant="standard"
					value={searchValue}
					onChange={onSearchChange}
					autoFocus={searchAutoFocus}
					placeholder="Search…"
					InputProps={{
						startAdornment: <SearchIcon fontSize="small" />,
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: searchValue ? 'visible' : 'hidden' }}
								onClick={clearSearch}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						),
					}}
					sx={{
						width: { xs: 1, sm: 'auto' },
						minWidth: 250,
						'& .MuiSvgIcon-root': {
							mr: 0.5,
						},
						'& .MuiInput-underline:before': {
							borderBottom: 1,
							borderColor: 'divider',
						},
					}} />}
				{filters}
			</Box>
			<GridActions hiddenToggle={hiddenToggle} actions={actions} />
		</Box>
	)
}


function GridActions({hiddenToggle, actions}) {
	return <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
		{hiddenToggle?.show &&
			<ToggleButtonGroup
				value={hiddenToggle.value}
				onChange={(e, newValues) => {
					if (newValues?.length) {
						hiddenToggle.onToggle(newValues)
					}
				}}
				aria-label="text formatting"
				size="small"
		  >
				<ToggleButton value="true">
					<Tooltip title="Show visible">
						<VisibilityIcon />
					</Tooltip>
				</ToggleButton>
				<ToggleButton value="false">
					<Tooltip title="Show invisible">
						<VisibilityOffIcon />
					</Tooltip>
				</ToggleButton>
			</ToggleButtonGroup>
		}
		{actions?.map((action, index) => {
			return (
				<TooltipButton
					key={index}
					title={action.hint}
					icon={action.icon}
					color={action.primary ? 'primary' : 'default'}
					size={action.iconSize || "medium"}
					onClick={action.onClick}
					disabled={action.disabled}
					loading={action.loading}
					to={action.link}
					sx={action.sx}
				/>
			);
		})}
	</Box>;
}

function SelectionToolBar({sx, selectionActions, selectionModel, pluralName, actionSubmitting, actionSubmitted, actionError}) {
	return (
		<Box
			sx={{
				pr: 0.5,
				pl: 2,
				justifyContent: 'space-between',
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				bgcolor: 'primary.main',
				...sx,
			}}
		>
			<Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1, flexWrap: { xs: 'wrap', md: 'nowrap' }, justifyContent: 'center' }}>
				<Typography variant="body" color="primary.contrastText">{selectionModel?.length} {pluralName} Selected</Typography>
			</Box>
			<GridSelectionActions
				selectionActions={selectionActions}
				selectionModel={selectionModel}
				pluralName={pluralName}
				actionSubmitting={actionSubmitting}
				actionSubmitted={actionSubmitted}
				actionError={actionError}
			/>
		</Box>
	)
}


function GridSelectionActions({selectionActions, selectionModel, pluralName, actionSubmitting, actionSubmitted, actionError}) {
	const [modal, setModal] = useState({open: false});
	useEffect(() => {actionSubmitted && setModal({open: false})}, [actionSubmitted])
	
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{selectionActions.filter(a => !a.hide).map((action, index) => {
				const handleClick = () => {
					action.onClick(selectionModel)
					if (action.sync) {
						setModal({...modal, open: false})
					}
				}
				let btn = (
					<IconButton
						key={index}
						aria-label={action.hint}
						size={"medium"}
						onClick={() => {
							if (action.skipConsent) {
								handleClick()
							} else {
								setModal({
									...action.modalProps,
									open: true,
									onConfirm: handleClick,
									confirmText: action.hint,
									title: action.hint,
									subtitle: `Are you sure you want to perform "${action.hint}" on ${selectionModel.length} ${pluralName}?`,
								})
							}
						}}
						sx={{...action.sx, color: 'primary.contrastText'}}
					>
						<action.icon fontSize={action.iconSize || "small"} />
					</IconButton>
				);
				return (
					<Tooltip key={index} title={action.hint}>
						{btn}
					</Tooltip>
				);
			})}
			<ConfirmDialog
				onCancel={() => setModal({...modal, open: false})}
				loading={actionSubmitting}
				alert={actionError}
				{...modal}
			/>
		</Box>
	)
}

export default function GridToolBar(props) {
	const {selectionModel, ...restProps} = props;

	if (selectionModel?.length) {
		return (
			<SelectionToolBar {...restProps} selectionModel={selectionModel} />
		)
	}
	return (
		<NonSelectionToolBar {...restProps} />
	)
}

