import _ from 'lodash';
import { useCallback, useState } from "react";
import fileSaver from 'file-saver';
import { createCSV } from 'utils/fileUtils';

function mapItemForDownload(itm, columns) {
	const updatedItm = {...itm};

	columns.forEach(col => {
		let val = itm[col.field];
		if (col.formatDownload && col.valueFormatter) {
			updatedItm[col.field] = col.valueFormatter(val, itm)
		} else if (col.valueGetter) {
			updatedItm[col.field] = col.valueGetter(val, itm)
		}
	});

	return updatedItm
}

interface UseDownloadCsvProps {fetch: () => Promise<any>, columns: any[], mapper?: (itm, columns) => any, getFileName: () => string}	
export function useDownloadCsv({fetch, columns, mapper = _.identity, getFileName}: UseDownloadCsvProps) {
	const [isDownloading, setIsDownloading] = useState(false);

	const download = useCallback(async() => {
		try {
			setIsDownloading(true);
			const statsResp = await fetch()
			const reportCols = columns.filter(c => !c.skipDownload)
			const mappedColumns = reportCols.reduce((aggr, col) => {
				aggr[col.field] = col.headerName || col.field;
				return aggr;
			}, {})
			const mappedStats = statsResp.items.map(i => mapItemForDownload(mapper(i, reportCols), reportCols))
			const csv = await createCSV(mappedStats, {columns: mappedColumns});
			fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `${getFileName()}.csv`);
    
		} catch (err) {
			console.error(err);
		} finally {
			setIsDownloading(false);
		}

	}, [fetch, columns, mapper, getFileName])

	return {isDownloading, download}
}