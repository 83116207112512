import _ from 'lodash';
import axios from 'services/axios';
import {FetchCampaignsProps, UpsertCampaignPayload} from './api.interface';
import queryString from 'query-string';

export async function fetchCampaigns({pagination, filter, sortModel, workspaceIds}: FetchCampaignsProps) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {query: {campaign: {...filter.campaign}, offer: filter.offer, search: filter.text}};
	
	if (workspaceIds?.length) {
		payload.query.campaign.workspaceId = {op: 'in', value: workspaceIds};
	}
	

	const orderBy = sortModel?.length ? `campaign.${sortModel[0].field}` : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/campaigns/query?${queryStr}`;
	const resp = await axios.post(url, payload);

	return resp.data;
}

export async function fetchSingleCampaign(id: string) {
	const url = `campaigns/${id}`;
	const {data} = await axios.get(url);   
	return data;
}

export async function upsertCampaign({campaign}: UpsertCampaignPayload) {
	const method = campaign.id ? axios.patch : axios.post;
	const url = campaign.id ? `campaigns/${campaign.id}` : `campaigns`;
	const {data} = await method(url, campaign);   
	return data;
}

export async function autoAdjustCampaignsWeights({campaignIds}: {campaignIds: string[]}) {
	const campaignsResp = await fetchCampaigns({filter: {campaign: {id: {op: 'in', value: campaignIds}}}})
	const promises = await campaignsResp.items.map(cmp => {
		const offers = cmp.offers.map(offer => {
			const weight = Math.round(offer.trafficCapLimit / 1000);
			return {...offer, weight};
		})
		return upsertCampaign({campaign: {...cmp, offers}});
	})
	await Promise.all(promises)
}


export async function deleteCampaign({id}) {
	const url = `campaigns/${id}`;
	const {data} = await axios.delete(url);
	return data;
}

export async function detachOffersFromCampaigns(offerIds: string[]) {
	const offers = await axios.post('/offers/query', {query: {offer: {id: {op: 'in', value: offerIds}}}})
	const campaignIds = _.uniq(offers.data.items.flatMap(offer => offer.connectedCampaigns).map(c => c.id));
	const campaigns = await axios.post('/campaigns/query', {query: {campaign: {id: {op: 'in', value: campaignIds}}}})
	const promises = campaigns.data.items.map(campaign => {
		const offers = campaign.offers.filter(offer => !offerIds.includes(offer.id));
		return axios.patch(`/campaigns/${campaign.id}`, {offers});
	})
	await Promise.all(promises);
}

export const formatError = (error) => {
	switch (error?.response?.data?.key) {
	default:
		return 'Oops. Request failed';
	}
}
