import {getFlavorConfig} from "./flavorConfig";

const tenant = process.env.REACT_APP_DEPLOYMENT_FLAVOR
const deploymentEnv = process.env.NODE_ENV;
const isDebug = process.env.NODE_ENV === 'development';
const config = {
	isDebug,
	appName: 'Venus',
	deploymentEnv,
	publicFolder: process.env.PUBLIC_URL,
	muiLicenseKey: process.env.REACT_APP_MUI_LICENSE_KEY,
	tenant,
	flavorConfig: getFlavorConfig(tenant),
	idleThrottle: 5,
	logoutThrottle: 1000,
	sessionIdleExpirationSeconds: 3 * 60 * 60,
	heartbitPollingTime: 5 * 60 * 1000,
	loginPaths: ['/login'],
	paginationOptions: [5, 10, 20, 50, 100, 400],
	paginationDefaultValue: 50,
	minPassLength: 6,
	queriesStaleSeconds: 1 * 60 * 60,
	statusColors: {
		enabled: {main: '#269f26', border: '#cfe4c1'},
		disabled: {main: '#ce2222', border: '#f5c4c4'},
		warning: {main: '#ffbe46', border: '#ffe5be'},
	},
};
export default config;
