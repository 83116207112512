import _ from 'lodash';
import { formatNum, secondsToHM } from "utils/transformUtils";
import { CountryFlag } from "components/CountryFlag/CountryFlag";
import {
	Loop as LoopIcon,
	Calculate as CalculateIcon,
} from '@mui/icons-material'
import { Box, Tooltip } from "@mui/material";
import { TrafficCapColumn } from "components/TrafficCapColumn/TrafficCapColumn";
import { EditButton } from "./editButton";
import { CopyButton } from "./copyButton";
import { getCampaignLink } from "utils/campaignUtils";
import { offerStagesMap } from "utils/autoComplete";
import { CampaignsColumn } from "./campaignsColumn";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { IconUp } from 'components/SvgIcons/IconUp';
import { IconDown } from 'components/SvgIcons/IconDown';

const defaultValueFormatter = val => formatNum(val, 1);
const cvrValueFormatter = val => formatNum(val, 2);

function StatsValueCell({row, column, comparePrevious, valueFormatter = defaultValueFormatter, suffix = ''}) {

	const value = row?.[column];
	const formattedValue = `${valueFormatter(value ?? '')}${suffix}`;
	
	if (!comparePrevious || row.id === '_totals') {return formattedValue || ''}
	
	const compareValue = row.previous ? row.previous[column] : null;
	const formattedCompare = row.previous && `${valueFormatter(compareValue)}${suffix}`

	let icon;
	const iconSx = {position: 'absolute', width: 8, left: 0, top: '50%', transform: 'translateY(-50%)'}
	if (!_.isNil(compareValue) && !_.isNil(value) && compareValue !== value) {
		icon = value > compareValue ? <IconUp sx={iconSx} /> : <IconDown sx={iconSx} />
	}
	return (
		<Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5, position: 'relative', pl: 1.5, lineHeight: 1, fontSize: 12, width: '100%'}}>
			<Box>{formattedValue}</Box>
			<Box>{formattedCompare}</Box>
			{icon}
		</Box>
	);
}

export function getColumns(groupBy1, globalSettings, filter) {
	const allColumns: any[] = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			global: true, skipDownload: true,
			width: 30,
			minWidth: 30,
		},
		// global start
		{ field: 'profitability', skipDownload: true, global: true, filterable: false, headerName: '', align: 'center', minWidth: 16, width: 16, valueGetter: (_val, row) => row.profit > 0 ? '+' : row.profit < 0 ? '-' : '•'},
		{ field: 'edit', skipDownload: true, global: true, filterable: false, headerName: '', align: 'center', minWidth: 20, width: 20, sortable: false, renderCell: ({row}) => row.id !== '_totals' && <EditButton entity={groupBy1} row={row} />},
		{ field: 'date', global: true, hidden: !filter.flatten, headerName: 'Date', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 80, valueGetter: (_val, row) => row.date?.slice(0, 10)},
		// campaign
		{ field: 'copyUrl', forGroups: ['campaign'], headerName: '', filterable: false, align: 'center', minWidth: 16, width: 16, sortable: false, renderCell: ({row}) => row.campaign && row.trafficSources.length === 1 && <CopyButton text={getCampaignLink(row.campaign, row.trafficSources[0], globalSettings)} />},
		// campaign
		{ field: 'campaign', forGroups: ['campaign'], headerName: 'Campaign', filterable: false, minWidth: 180, flex: 1, valueGetter: (_val, row) => row.campaign?.name},
		{ field: 'campaignOrder', forGroups: ['campaign'], headerName: 'Daily', filterable: false, minWidth: 110, valueGetter: (_val, row) => row.campaign?.order},
		// trafficSource
		{ field: 'trafficSource', forGroups: ['trafficSource'], headerName: 'Traffic Source', filterable: false, minWidth: 100, width: 150, valueGetter: (_val, row) => row.trafficSource?.name},
		// offer
		{ field: 'offerName', forGroups: ['offer'], headerName: 'Offer', filterable: false, minWidth: 150, flex: 1},
		{ field: 'offerOrder', forGroups: ['offer'], headerName: 'Daily', filterable: false, minWidth: 110, align: 'right', renderCell: ({row}) => row.offer && <TrafficCapColumn trafficCapLimit={row.offer?.trafficCapLimit} activityStatus={row.offer?.activityStatus} />},
		{ field: 'offerBU', forGroups: ['offer'], headerName: 'V/CL', filterable: false, description: 'Yesterday Visits / Cap Limit', width: 50, align: 'right', valueGetter: (_, row) => {
			if (row.id === '_totals') {return ''}
			if (!row.offer || !row.offer.trafficCapLimit) {return -1};
			return formatNum((row.offer.activityStatus?.yesterday?.visits || 0) / row.offer.trafficCapLimit * 100, 0) + '%'
		}},
		// global
		{ field: 'visits', global: true, headerName: 'Visits', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 80, renderCell: ({row}) => (<StatsValueCell row={row} column="visits" comparePrevious={filter.comparePrevious} />)},
		// offer
		{ field: 'offerDate', forGroups: ['offer'], headerName: 'Offer Date', filterable: false, minWidth: 110, align: 'right', valueFormatter: (value) => value?.slice(0, 10)},
		{ field: 'offerStage', forGroups: ['offer'], headerName: 'Stage', filterable: false, minWidth: 70, width: 70, sortable: false, valueFormatter: (value) => offerStagesMap[value]?.name},
		{ field: 'offerCurrentCampaigns', forGroups: ['offer'], headerName: 'Campaign', filterable: false, minWidth: 170, sortable: false, renderCell: ({row}) => (<CampaignsColumn offerId={row.offer?.id} campaigns={row?.offerCurrentCampaigns} />)},
		{ field: 'trafficSources', forGroups: ['offer', 'campaign'], headerName: 'TS', filterable: false, minWidth: 130, sortable: false, valueGetter: (_val, row) => row.trafficSources?.map(ts => ts?.name).join(', ')},
		// network
		{ field: 'networkName', forGroups: ['network', 'offer'], headerName: 'Network', filterable: false, minWidth: 100, valueGetter: (_val, row) => row.networkName},
		// site, offer
		{ field: 'siteName', forGroups: ['site', 'offer'], headerName: 'Site', filterable: false, minWidth: 100, valueGetter: (_val, row) => row.siteName},
		// campaign
		{ field: 'campaignGeo', forGroups: ['campaign'], headerName: 'Geo', filterable: false, minWidth: 60, width: 60, sortable: true, renderCell: ({value}) => (<CountryFlag country={value} />)},
		// offer
		{ field: 'offerGeo', forGroups: ['offer'], headerName: 'Geo', filterable: false, minWidth: 60, width: 60, sortable: false, renderCell: ({value}) => (<CountryFlag country={value} />)},
		// geo
		{ field: 'geo', forGroups: ['geo'], headerName: 'Geo', filterable: false, minWidth: 60, width: 60, sortable: false, renderCell: ({value}) => (<CountryFlag country={value} />)},
		// global end
		{ field: 'roi', global: true, headerName: 'ROI', description: 'Calc from Net Rev', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="roi" comparePrevious={filter.comparePrevious} suffix='%' />)},
		{ field: 'defaultConversions', global: true, headerName: 'Conv', description: 'Conversions', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="defaultConversions" comparePrevious={filter.comparePrevious} />)},
		{ field: 'cvr', global: true, headerName: 'CVR', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="cvr" valueFormatter={cvrValueFormatter} comparePrevious={filter.comparePrevious} />)},
		{ field: 'revenue', global: true, headerName: 'Net Rev', description: 'Gross Rev - Rejections', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 80, renderCell: ({row}) => (<StatsValueCell row={row} column="revenue" comparePrevious={filter.comparePrevious} />)},
		{ field: 'defaultRevenue', global: true, headerName: 'Grs Rev', description: 'Rev without rejections', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 80, renderCell: ({row}) => (<StatsValueCell row={row} column="defaultRevenue" comparePrevious={filter.comparePrevious} />)},
		{ field: 'cost', global: true, headerName: 'Cost', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="cost" comparePrevious={filter.comparePrevious} />)},
		{ field: 'profit', global: true, headerName: 'Profit', description: 'Calc from Net Rev', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="profit" comparePrevious={filter.comparePrevious} />)},
		{ field: 'rejectionRevenue', global: true, headerName: 'Rej. Rev', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="rejectionRevenue" comparePrevious={filter.comparePrevious} />)},
		{ field: 'mttc', global: true, headerName: 'MTTC', description: 'Mean Time To Convert', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, valueFormatter: secondsToHM},
		{ field: 'rpc', global: true, headerName: 'RPC', description: 'Calc from Net Rev', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="rpc" comparePrevious={filter.comparePrevious} />)},
		{ field: 'rejectionConversions', global: true, headerName: '# Rej.', type: 'number', align: 'left', headerAlign: 'left', minWidth: 60, width: 70, renderCell: ({row}) => (<StatsValueCell row={row} column="rejectionConversions" comparePrevious={filter.comparePrevious} />)},
		{ field: 'costSources', global: true, headerName: 'Cost Src', minWidth: 80, width: 80, sortable: false, renderCell: ({value}) => {
			return value?.sort().map((cs, i) => {
				switch (cs) {
				case 'auto':
					return <Tooltip key={i} title="Estimated"><CalculateIcon fontSize="small" /></Tooltip>
				case 'sync':
					return <Tooltip key={i} title="Synced from TS"><LoopIcon fontSize="small" /></Tooltip>
				default:
					return cs
				}
			})
		} },
	]
	return allColumns.filter(column => !column.hidden && (column.global || column.forGroups.includes(groupBy1)))
}