import _ from 'lodash';
import { useCallback, useEffect } from "react";
import {DialogActions, DialogContent, Button, Box, Alert} from '@mui/material';
import Yup from 'services/yup';

// components
import { useModalState } from "utils/hooks";
import { Formik, Field } from 'formik';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import {
	Add as AddIcon,

} from '@mui/icons-material';
import DraggableDialog from "components/DraggableDialog";
import FormikTextField from 'components/Form/FormikTextField';
import { CopyId } from 'components/CopyId';
import { useMutateArrayItemQuery, useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchSingleNetwork, upsertNetwork, formatError } from 'modules/networks/api';
import Autocomplete from 'components/Form/AutoCompleteFormik';
import { currencyCodes, fetchWorkspaces, networkKinds } from 'utils/autoComplete';
import { AsyncAutoCompleteFormik } from 'components/Form/AsyncAutoCompleteFormik';

function getSchema() {
	let schema = Yup.object().shape({
		name: Yup.string().trim().required(),
		kind: Yup.string().nullable().trim().required(),
		workspaceId: Yup.string().trim().nullable().required(),
		currencyCode: Yup.string().trim().required(),
		comments: Yup.string().trim().nullable(),
	})
	return schema;
}

const initalValues = {
	name: '', kind: null, workspaceId: null, currencyCode: 'USD', comments: '',
}


export function CreateEditNetwork() {
	const {modalState, toggleModal} = useModalState('network')

	const networkQuery = useWrappedQuery({
		enabled: !!modalState.id,
		queryFn: () => fetchSingleNetwork(modalState.id),
		queryKey: ['network', modalState.id],
	})

	const rawNetwork = networkQuery.data;
	const network = (rawNetwork && modalState.reason === 'Duplicate') ? _.omit(rawNetwork, ['id']) : rawNetwork;
	
	const closeModal = useCallback(function closeModal() {
		toggleModal(false);
	}, [toggleModal]);
	
	// useEffect(() => {
		
	// }, [modalState.open])
		
	const saveNetworkQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertNetwork({network: data}),
		queryKey: modalState.id && ['network', modalState.id],
		queryListKey: ['networksList'],
		formatError,
	})
	const resetQuery = saveNetworkQuery.reset;
	const fieldsDisabled = networkQuery.isFetching || saveNetworkQuery.isLoading
	const submitError = saveNetworkQuery.formattedError;
		
	useEffect(() => {
		if (modalState.open) {
			resetQuery();
		}
	}, [modalState, resetQuery])
      
	function handleFormSubmit(values) {
		const castValues = getSchema().cast({
			...values,
		});
		saveNetworkQuery.mutateAsync(castValues)
			.then(() => closeModal())
	}

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		closeModal();
	}
	const title = 
		networkQuery.isFetching ?
			'Loading...' :
			modalState.id ? `${modalState.reason} Network ${network?.n || ''}` : "Create New Network";
	return (
		<DraggableDialog
			open={modalState.open}
			onClose={handleClose}
			fullWidth
			title={title}
			titleId="network-dialog-title"
			titleSx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}
		>
			<Formik
				initialValues={_.merge({}, initalValues, network)}
				enableReinitialize
				validationSchema={getSchema()}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm }) => (
					<>
						<DialogContent dividers>
							<CopyId id={network?.id} sx={{mb: 1}} />
							<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
								<PromptIfDirty />
								<Field
									component={FormikTextField}
									name="name"
									type="text"
									label="Network name"
									disabled={fieldsDisabled}
									size="small"
								/>
								<Field
									component={Autocomplete}
									name="kind"
									type="text"
									label="Network Kind"
									fullWidth
									disabled={fieldsDisabled}
									options={networkKinds}
									size="small"
								/>
								<AsyncAutoCompleteFormik
									entityName='workspace'
									limit={15}
									getItems={fetchWorkspaces}
									label="Workspace"
									name="workspaceId"
									disabled={fieldsDisabled}
									sx={{flex: 1}}
									size="small"
								/>
								<Field
									component={Autocomplete}
									name="currencyCode"
									type="text"
									label="Currency"
									fullWidth
									disabled={fieldsDisabled}
									options={currencyCodes}
									size="small"
								/>
								<Field
									component={FormikTextField}
									name="comments"
									type="text"
									label="Comments"
									multiline
									disabled={fieldsDisabled}
									size="small"
								/>
								{submitError && <Alert severity="error">{submitError}</Alert>}
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeModal}>Cancel</Button>
							<ProgressButton
								variant="contained"
								color="primary"
								sxWrap={{alignSelf: 'flex-start'}}
								onClick={submitForm}
								disabled={fieldsDisabled}
								loading={saveNetworkQuery.isLoading}
							>
								Save
							</ProgressButton>
						</DialogActions>
					</>
				)}
			</Formik>
		</DraggableDialog>
	)
}
