import { Box, SvgIcon } from "@mui/material";

export function IconUp(props) {
	return (
		<SvgIcon color="success" {...props}>
			<Box component="svg" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="7 9 10 5">
				<path d="m7 14 5-5 5 5z"></path>
			</Box>
		</SvgIcon>
	)
}