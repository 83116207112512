import _ from 'lodash'
import {Box, Autocomplete, Paper, Button} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { CustomizedTextField } from 'components/CustomizedTextField';

function AutocompletePaper({multiple, children, onSelectAll, ...props}) {

	return (
		<Paper {...props}>
			{!!multiple && <Box sx={{px: 1, py: 0.1}}>
				<Button onMouseDown={e => e.preventDefault()} onClick={onSelectAll} variant="text" size="small" sx={{fontSize: 12, p: 0}}>Select All</Button>
			</Box>}
			{children}
		</Paper>
	)
}
const invalidValue = '<Invalid>';
const loadingValue = 'Loading...';
export default function AsyncAutoComplete(props) {
	const {
		value, missingDependency, onItemsLoaded, disabled, name, error, onChange, entityName, entitySearchKey, optionKeyProp = 'id', optionLabelProp = 'name',
		where, getItems, limit = -1, textFieldProps, minInputLength = 0, simpleQuery, groupBy, renderOption, inputRef, onOptionSelected, multiple, ...restProps
	} = props;
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [options, setOptions] = useState([]);
	const [optionsKeys, setOptionsKeys] = useState(_.map(options, optionKeyProp));
	const [optionsMap, setOptionsMap] = useState(_.keyBy(options, optionKeyProp));
	const [filter, setFilter] = useState('');
	const [loading, setLoading] = useState(!missingDependency && filter.length >= minInputLength);
	const [fetchedFilter, setFetchedFilter] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const prepareOptions = useCallback((appendedItems) => {
		const allKeys = _.map(appendedItems, optionKeyProp);
		const keyMap = _.keyBy(appendedItems, optionKeyProp);
		selectedOptions.forEach(selectedOption => {
			const matchingId = _.get(selectedOption, optionKeyProp);
			if (!keyMap[matchingId]) {
				allKeys.push(matchingId);
				keyMap[matchingId] = selectedOption;
			}
		})
		setOptionsKeys(allKeys);
		setOptionsMap(keyMap);
		setOptions(appendedItems)
	}, [optionKeyProp, selectedOptions])

	//submit determined filter
	const debouncedSubmit = useCallback(_.debounce(async(whereFilter, selectedOptions, limitItems) => { // eslint-disable-line react-hooks/exhaustive-deps
		try {
			setLoading(true)
			const fetchedItems = await getItems(whereFilter, limitItems);
			const selectedKeys = selectedOptions.map(so => so[optionKeyProp]);
			const appendedItems = [
				...selectedOptions,
				...fetchedItems.filter(fi => !selectedKeys.includes(fi[optionKeyProp])),
			];
			prepareOptions(appendedItems)
			setLoading(false)
			onItemsLoaded && onItemsLoaded(appendedItems);
		} catch (err) {console.error(err);}
	}, 300), [getItems, limit]);
	
	// determine filter
	useEffect(() => {
		if (missingDependency) {
			return;
		}
		if (limit < 0 && fetchedFilter) {return;}
		
		const matchingName = multiple ? '' : _.get(selectedOptions[0], optionLabelProp);
		const actualFilter = [matchingName, invalidValue, loadingValue].includes(filter) ? '' : filter.trim();

		let internalFilter;
		const hasValue = multiple ? value?.length : value;
		let limitItems = limit || 15;
		if (limit > 0) {
			if (hasValue && (!selectedOptions.length || !actualFilter)) {
				const operator = multiple ? {op: 'in', value} : value;
				internalFilter = {[entityName]: {[optionKeyProp]: operator}}
				limitItems = multiple ? value.length : 1
			} else if (actualFilter) {
				const searchKey = entitySearchKey || optionLabelProp;
				internalFilter = {$and: [where, {[entityName]: {[searchKey]: {op: 'ilike', value: `%${actualFilter}%`}}}].filter(_.identity)}
			} else {
				internalFilter = where;
			}
		} 
		let itemsWhere = simpleQuery ? `%${actualFilter}%` : internalFilter;
		if (!_.isEqual(itemsWhere, fetchedFilter) && filter.length >= minInputLength) {
			setFetchedFilter(itemsWhere)
			debouncedSubmit(itemsWhere, selectedOptions, limitItems);
		}
	}, [filter, value, debouncedSubmit, minInputLength, simpleQuery, optionKeyProp, optionLabelProp, selectedOptions, fetchedFilter, where, entityName, entitySearchKey, limit, missingDependency, prepareOptions, multiple])

	// clear filter when getItems change, so data is refreshed
	useEffect(() => {setFetchedFilter(false)}, [getItems])

	useEffect(() => {
		const arrVal = multiple ? value : [value];
		const selected = arrVal.map(val => optionsMap[val]).filter(f => f)
		setSelectedOptions(selected);
		const selectedMatching = multiple ? selected : selected[0];
		onOptionSelected && onOptionSelected(selectedMatching)
	}, [optionsMap, value, onOptionSelected, multiple])

	function handleInputChange(e, newVal, reason) {
		if (reason !== 'reset') {
			setFilter(newVal)
		}
	}
	function handleChange(value) {
		onChange(value, optionsMap[value]);
	}

	function onSelectAll() {
		handleChange(optionsKeys);
	}
	if (!entityName) {
		console.error(new Error('Invalid entity name'));
		return null;
	}

	const actuallyDisabled = missingDependency || disabled;

	return (
		<Autocomplete
			{...restProps}
			disabled={actuallyDisabled}
			multiple={multiple}
			value={value}
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			onChange={(_e, value) => handleChange(value)}
			options={optionsKeys}
			autoHighlight
			filterOptions={(x) => x}
			onInputChange={handleInputChange}
			inputValue={!multiple ? undefined : isOpen ? filter : ''}
			PaperComponent={AutocompletePaper}
			ListboxProps={multiple ? {sx: {pt: 0}} : undefined}
			slotProps={{paper: {multiple, onSelectAll}}}
			getOptionLabel={(value) => _.get(optionsMap[value], optionLabelProp, loading ? loadingValue : invalidValue)}
			renderOption={(props, value) => {
				const option = optionsMap[value] || {[optionLabelProp]: invalidValue};
				if (renderOption) {
					return renderOption(value, optionsMap[value], props)
				}
				return (
					(
						<Box component="li"  {...props} key={option[optionKeyProp] || value}>
							{option[optionLabelProp]}
						</Box>
					)
				)
			}}
			groupBy={(optionId) => {
				if (!groupBy) {return groupBy}
				if (typeof groupBy === 'function') {
					return groupBy(optionsMap[optionId], optionId);
				}
				return _.get(optionsMap[optionId], groupBy)
			}}
			renderInput={(params) => (
				<CustomizedTextField
					{...textFieldProps}
					{...params}
					inputRef={inputRef}
					sx={{...textFieldProps?.sx, ...params.sx}}
					error={!!error}
					name={name}
					helperText={error || restProps.helperText}
					label={restProps.label}
					placeholder={restProps.placeholder}
					inputProps={{
						...params.inputProps,
						autoComplete: 'off', // disable autocomplete and autofill
					}}
					InputProps={{
						...params.InputProps,
						...textFieldProps?.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					  }}
				/>
			)}
		/>
	);
}
