import {Paper, Box} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	ContentCopy as ContentCopyIcon,
	LocalOffer as LocalOfferIcon,
	BarChart as BarChartIcon,
	Download as DownloadIcon,
} from '@mui/icons-material';
import _ from 'lodash';

// components
import PageTitle from "../../components/PageTitle";
import { useAvailableWindowHeight, useModalState, useQueryableFilter } from "utils/hooks";
import config from "config/config";
import { useMutateArrayItemQuery, useWrappedQuery } from "utils/reactQueryHooks";
import { fetchOffers, upsertOffer, formatError } from "modules/offers/api";
import { EnhancedColGridDef, EnhancedDataGrid } from "../../components/DataGrid/EnhancedDataGrid";
import { Offer } from "modules/offers/api.interface";
import { CountryFlag } from "components/CountryFlag/CountryFlag";
import { useWorkspaces } from "context/WorkspaceContext";
import TooltipButton from 'components/TooltipButton/TooltipButton';
import { fetchNetworks, fetchSites, offerStages, offerStagesMap } from 'utils/autoComplete';
import { CampaignsColumn } from 'pages/tracking/campaignsColumn';
import { useState } from 'react';
import AutoComplete from 'components/Form/AutoComplete';
import { formatNum } from 'utils/transformUtils';
import DateRangePicker from 'components/Form/DateRangePicker';
import AsyncAutoComplete from 'components/Form/AsyncAutoComplete';
import CountriesSelect from 'components/Form/CountriesSelect/CountriesSelect';
import { useDownloadCsv } from 'hooks/gridHooks';


const columnVisibilityModel = {updatedAt: false}
function getColumns({renderActionsColumn}): (EnhancedColGridDef)[] {
	return [
		{ field: 'updatedAt', type: 'string'},
		{ field: 'name', headerName: 'Name', filterable: false, minWidth: 350, flex: 1},
		{ field: 'stage', headerName: 'Stage', minWidth: 80, width: 80, type: 'string', valueFormatter: (value: string) => offerStagesMap[value]?.name},
		{ field: 'date', headerName: 'Date', minWidth: 100, type: 'string', formatDownload: true, valueFormatter: (value: Date) => value && value.toISOString().slice(0, 10)},
		{ field: 'site.name', headerName: 'Site', minWidth: 100, valueGetter: (_val, row) => row.site.name},
		{ field: 'network.name', headerName: 'Network', minWidth: 100, valueGetter: (_val, row) => row.network.name},
		{ field: 'geo', headerName: 'Geo', minWidth: 80, width: 80, renderCell: ({value}) => <CountryFlag country={value} />},
		{ field: 'activityStatus.yesterday.visits::numeric', headerName: 'T. Yesterday', width: 100, sortable: true, valueFormatter: (value) => formatNum(value), valueGetter: (_, row) => row.activityStatus?.yesterday?.visits},
		{ field: 'activityStatus.today.visits::numeric', headerName: 'T. Today', width: 100, sortable: true, valueFormatter: (value) => formatNum(value), valueGetter: (_, row) => row.activityStatus?.today?.visits},
		{ field: 'connectedCampaigns', headerName: 'Campaigns', sortable: false, width: 300, renderCell: ({row, value}) => <CampaignsColumn offerId={row.id} campaigns={value} />},
		{ field: 'comments', headerName: 'Comments', minWidth: 200, flex: 1, editable: true},
		// { field: 'tags', headerName: 'Tags', minWidth: 100, sortable: false, renderCell: ({row}) => <TagsColumn tags={row.tags} />},
		{ field: 'url', headerName: 'Url', filterable: false, width: 100},
		{ field: 'externalId', headerName: 'ExternalId', filterable: false, width: 100},
		{ field: 'actions', headerName: 'Actions', sortable: false, filterable: false, skipDownload: true, renderCell: renderActionsColumn, width: 120},
	]
}

function ActionsColumn({row, onEditClick, onDuplicateClick, onStatsClick}) {
	return (
		<>
			<TooltipButton
				title="Open Stats"
				icon={BarChartIcon}
				size="medium"
				onClick={() => onStatsClick(row)}
				sx={{p: 0.5}}
			/>
			<TooltipButton
				title="Duplicate"
				icon={ContentCopyIcon}
				size="medium"
				onClick={() => onDuplicateClick(row)}
				sx={{p: 0.5}}
			/>
			<TooltipButton
				title="Edit Offer"
				icon={EditIcon}
				size="medium"
				onClick={() => onEditClick(row)}
				sx={{p: 0.5}}
			/>
		</>
	)
}

function mapItemForDownload(itm) {
	const updatedItm = {...itm};

	updatedItm.connectedCampaigns = itm.connectedCampaigns?.map(c => c.name).join(', ')
	return updatedItm
}

const defaultFilter = {text: '', offerStage: undefined, dateRange: [null, null], geo: undefined};
const defaultSortModel = [{field: 'updatedAt', sort: 'desc'}]
export function OffersPage() {
	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: config.paginationDefaultValue, defaultSortModel});
	const gridHeight = useAvailableWindowHeight()
	const {setModal: setOfferModal} = useModalState('offer')
	const {selectedWorkspaces} = useWorkspaces();
	const [inlineOfferId, setInlineOfferId] = useState<string>();
	const {setModal: setOfferStatsModal} = useModalState('offerStats')

	const columns = getColumns({renderActionsColumn});

	const offerFilter = {text: filter.text, dateRange: filter.dateRange, offer: {
		stage: filter.offerStage,
		networkId: filter.networkId,
		siteId: filter.siteId,
		geo: filter.geo,
	}}
	const {isDownloading, download} = useDownloadCsv({
		columns, mapper: mapItemForDownload,
		getFileName: () => `offers`,
		fetch: () => fetchOffers({filter: offerFilter, sortModel, workspaceIds: selectedWorkspaces.ids}),
	});

	const {isLoading: isOffersLoading, data: offersResponse} = useWrappedQuery({
		queryKey: ['offersList', filter, pagination, sortModel, selectedWorkspaces.ids],
		queryFn: () => fetchOffers({
			pagination,
			sortModel: sortModel,
			filter: offerFilter,
			workspaceIds: selectedWorkspaces.ids,
		}),
	});

	const saveOfferQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertOffer({offer: data}),
		queryKey: inlineOfferId ? ['offer', inlineOfferId] : [],
		queryListKey: ['offersList'],
		formatError,
	})

	function openOfferModal(id?: string, reason?: string) {
		setOfferModal({id, open: true, reason})
	}

	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onEditClick={() => openOfferModal(params.row.id, 'Update')}
				onDuplicateClick={row => openOfferModal(params.row.id, 'Duplicate')}
				onStatsClick={row => setOfferStatsModal({offerId: row.id, offerName: row.name, open: true})}
			/>
		);
	}

	function handleDoubleCellClick(params) {
		if (!['actions', 'comments'].includes(params.field)) {
			openOfferModal(params.row.id, 'Update')
		}
	}

	function handleUpdatedInline(offer) {
		setInlineOfferId(offer.id);
		saveOfferQuery.mutateAsync(offer)
			.catch(console.error)
	}

	return (
		<>
			<PageTitle title="Offers" icon={LocalOfferIcon} />
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Paper sx={{height: gridHeight}}>
					<EnhancedDataGrid<Offer>
						items={offersResponse?.items}
						loading={isOffersLoading}
						columns={columns}
						columnVisibilityModel={columnVisibilityModel}
						rowCount={offersResponse?._meta?.total}
						pagination
						paginationMode="server"
						paginationModel={pagination}
						density='compact'
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						editMode='cell'
						onProcessRowUpdateError={console.log}
						pinnedColumns={{right: ['actions']}}
						processRowUpdate={(newRow, oldRow) => {
							const validFields = ['comments'];
							const toUpdate = _.pickBy(newRow, (val, key) => validFields.includes(key) && newRow[key] !== oldRow[key])
							if (!_.isEmpty(toUpdate)) {
								handleUpdatedInline({...toUpdate, id: newRow.id});
							}
							return newRow
						}}
						pluralName="Offers"
						filter={filter}
						setFilter={setFilter}
						showHiddenToggle
						// actionSubmitted={offerJustSubmitted}
						// actionSubmitting={offerSubmitting}
						actions={[{
							icon: DownloadIcon,
							primary: true,
							hint: 'Download',
							loading: isDownloading,
							onClick: download,
						}, {
							icon: AddIcon,
							primary: true,
							hint: 'Add new offer',
							onClick: () => openOfferModal(),
						}]}
						filters={(
							<>
								<AutoComplete
									options={offerStages}
									value={filter.offerStage}
									onChange={(_e, newVal) => setFilter({...filter, offerStage: newVal})}
									label="Stage"

									textFieldProps={{
										sx: {
											width: 140,
										},
									}}
								/>
								<DateRangePicker
									name="dateRange"
									value={filter.dateRange}
									label="Date Range"
									textFieldProps={{sx: {minWidth: 260}, size: 'small'}}
									onChange={(newValue) => {setFilter({...filter, dateRange: newValue})}}
								/>
								<AsyncAutoComplete
									entityName='network'
									limit={15}
									getItems={fetchNetworks}
									label="Network"
									name="networkId"
									value={filter.networkId}
									onChange={(newValue) => {setFilter({...filter, networkId: newValue})}}
									where={{network: {workspaceId: !!selectedWorkspaces.ids.length ? {op: 'in', value: selectedWorkspaces.ids} : null}}}
									sx={{flex: 1}}
									textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
									size="small"
								/>
								<AsyncAutoComplete
									entityName='site'
									limit={15}
									getItems={fetchSites}
									label="Site"
									name="siteId"
									value={filter.siteId}
									onChange={(newValue) => {setFilter({...filter, siteId: newValue})}}
									where={{site: {workspaceId: !!selectedWorkspaces.ids.length ? {op: 'in', value: selectedWorkspaces.ids} : null}}}
									sx={{flex: 1}}
									textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
									size="small"
								/>
								<CountriesSelect
									noAll
									size="small"
									label="Geo"
									name="geo"
									value={filter.geo || null}
									onChange={(newValue) => {setFilter({...filter, geo: newValue})}}
									sx={{
										'& .MuiFormLabel-root': {lineHeight: 1},
									}}
									textFieldProps={{
										sx: {
											width: 140,
										},
									}}
								/>
							</>
						)}
						onCellDoubleClick={handleDoubleCellClick}
					/>
				</Paper>
			</Box>
		</>
	)
}
