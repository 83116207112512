import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from "react";
import {DialogActions, DialogContent, Button, Box, Alert} from '@mui/material';
import Yup from 'services/yup';

// components
import { useModalState } from "utils/hooks";
import { Formik, Field, FormikProps } from 'formik';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import PromptIfDirty from 'components/PromptIfDirty/PromptIfDirty';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	ContentCopy as ContentCopyIcon,
	Link as LinkIcon,
	BarChart as BarChartIcon,
	AutoFixHigh as AutoFixHighIcon,
} from '@mui/icons-material';
import DraggableDialog from "components/DraggableDialog";
import FormikTextField from 'components/Form/FormikTextField';
import { CopyId } from 'components/CopyId';
import { useMutateArrayItemQuery, useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchSingleCampaign, upsertCampaign, formatError } from 'modules/campaigns/api';
import { AsyncAutoCompleteFormik } from 'components/Form/AsyncAutoCompleteFormik';
import { fetchCampaigns, fetchOffers, fetchTrafficSources, fetchWorkspaces } from 'utils/autoComplete';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import CountriesSelectFormik from 'components/Form/CountriesSelect/CountriesSelectFormik';
import { useSubscribe } from 'hooks/pubsub';
import { Campaign } from 'modules/campaigns/api.interface';
import { useWorkspaces } from 'context/WorkspaceContext';
import { getCampaignLink } from 'utils/campaignUtils';
import { useSelector } from 'react-redux';
import * as SettingSelectors from 'modules/settings/selectors';
import { Flex } from 'components/Flex/Flex';
import { formatNum } from 'utils/transformUtils';

function getSchema() {
	let schema = Yup.object().shape({
		name: Yup.string().trim().required(),
		geo: Yup.string().trim().nullable().required(),
		trafficSourceId: Yup.string().trim().nullable().required(),
		workspaceId: Yup.string().trim().nullable().required(),
		fallbackCampaignId: Yup.string().trim().nullable(),
		offers: Yup.array().of(Yup.object().shape({
			id: Yup.string().nullable().required('Required')
				.test('unique', 'Multiple identical offers', (value, ctx) => {
					const ctxAny = ctx as any;
					return ctxAny.from[1].value.offers.filter(o => o.id === value).length === 1
				}),
			weight: Yup.number().min(0).required('Required'),
		})).min(0),
		externalId: Yup.string().nullable().trim(),
		comments: Yup.string().nullable().trim(),
	})
	return schema;
}

const defaultOffer = {id: null, weight: 100};
const initalValues = {
	name: '', geo: null, workspaceId: null, fallbackCampaignId: null, trafficSourceId: null, externalId: '',
	offers: [], comments: '',
}


export function CreateEditCampaign() {
	const {modalState: campaignModal, toggleModal: toggleCampaignModal} = useModalState('campaign')
	const {setModal: setOfferModal} = useModalState('offer')
	const [createOfferCtx, setCreateOfferCtx] = useState({enabled: false, index: 0, promptEnabled: true});
	const formikRef = useRef<FormikProps<Campaign>>(null);
	const {selectedSingleWS} = useWorkspaces();
	const globalSettings = useSelector(SettingSelectors.globalSettings)
	const {setModal: setCampaignStatsModal} = useModalState('campaignStats')

	const {refetch: refetchCamaign, data: rawCampaign, isFetching: isFetchingCampaign} = useWrappedQuery({
		enabled: !!campaignModal.id,
		queryFn: () => fetchSingleCampaign(campaignModal.id),
		queryKey: ['campaign', campaignModal.id],
	})

	useEffect(() => {
		if (campaignModal.id) {
			refetchCamaign()
		}
	}, [campaignModal.id, refetchCamaign])
	const campaign = (rawCampaign && campaignModal.reason === 'Duplicate') ? _.omit(rawCampaign, ['id']) : rawCampaign;
	
	useSubscribe("offerCreated", useCallback((channel, data) => {
		if (createOfferCtx.enabled) {
			const offers = formikRef.current?.values?.offers || [];
			if (offers?.[createOfferCtx.index]) {
				formikRef.current?.setFieldValue(`offers.${createOfferCtx.index}`, {...defaultOffer, ...data});
			} else {
				const newOffers = [...offers];
				newOffers[createOfferCtx.index] = {...defaultOffer, id: data.id}
				formikRef.current?.setFieldValue(`offers`, newOffers);
			}
			// setValue(v => v + 1);
		}
	}, [createOfferCtx]));
	useSubscribe("offerModalClosed", useCallback(() => {
		setCreateOfferCtx(val => ({...val, promptEnabled: true}))
	}, []))

	const closeModal = useCallback(function closeModal() {
		toggleCampaignModal(false);
	}, [toggleCampaignModal]);
	
	useEffect(() => {
		if (!campaignModal.open) {
			setCreateOfferCtx({enabled: false, index: 0, promptEnabled: true})
		}
	}, [campaignModal.open])

	const saveCampaignQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertCampaign({campaign: data}),
		queryKey: campaignModal.id && ['campaign', campaignModal.id],
		queryListKey: ['campaignsList'],
		formatError,
	})
	const resetQuery = saveCampaignQuery.reset;
	const fieldsDisabled = isFetchingCampaign || saveCampaignQuery.isLoading
	const submitError = saveCampaignQuery.formattedError;
		
	useEffect(() => {
		if (campaignModal.open) {
			resetQuery();
		}
	}, [campaignModal, resetQuery])
      
	function handleFormSubmit(values) {
		const castValues = getSchema().cast({
			...values,
		});
		saveCampaignQuery.mutateAsync(castValues)
			.then(() => closeModal())
	}

	const handleClose = (event, reason) => {
		if (reason === "backdropClick") 
			return;
		closeModal();
	}
	const title = 
		isFetchingCampaign ?
			'Loading...' :
			campaignModal.id ? `${campaignModal.reason} Campaign ${campaign?.n || ''}` : "Create New Campaign";
	return (
		<DraggableDialog
			open={campaignModal.open}
			onClose={handleClose}
			fullWidth
			title={title}
			titleId="campaign-dialog-title"
			titleSx={{bgcolor: 'primary.main', color: t => t.palette.primary.contrastText}}
		>
			<Formik
				initialValues={_.merge({}, initalValues, {workspaceId: selectedSingleWS}, campaign)}
				innerRef={formikRef}
				enableReinitialize
				validationSchema={getSchema()}
				onSubmit={(values, { setSubmitting }) => {
					handleFormSubmit(values);
					setSubmitting(false);
				}}
			>
				{({ submitForm, setFieldValue, setValues, values }) => {
					const offersTotal = values.offers.reduce((aggr, off) => aggr + (off.weight || 0), 0)
					const activeOffers = values.offers.filter(o => o.weight)
					return (
						<>
							<DialogContent dividers>
								{!!campaign?.id && (
									<Flex justify="space-between" align="center" sx={{mb: 1}}>
										<CopyId id={campaign?.id} />
										<Flex gap={0}>
											<TooltipButton
												title="Copy Campaign URL"
												icon={LinkIcon}
												size="small"
												color="primary"
												onClick={() => {
													const campaignLink = getCampaignLink(campaign, campaign.trafficSource, globalSettings);
													navigator.clipboard.writeText(campaignLink);
												}}
											/>
											<TooltipButton
												title="Open Stats"
												icon={BarChartIcon}
												size="small"
												color="primary"
												onClick={() => {
													setCampaignStatsModal({open: true, campaignId: campaign.id, campaignName: campaign.name})
												}}
											/>
										</Flex>
									</Flex>
								)}
								<Box component="form" sx={{display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600}}>
									<PromptIfDirty disabled={!createOfferCtx.promptEnabled} />
									<Field
										component={FormikTextField}
										name="name"
										type="text"
										label="Campaign name"
										disabled={fieldsDisabled}
										size="small"
									/>
									<CountriesSelectFormik
										noAll
										label="Geo"
										name="geo"
										disabled={fieldsDisabled}
										size="small"
										onChange={() => {
											setFieldValue('offers', [])
										}}
									/>
									<AsyncAutoCompleteFormik
										entityName='workspace'
										limit={15}
										getItems={fetchWorkspaces}
										label="Workspace"
										name="workspaceId"
										onChange={(newValue) => {
											setValues({...values, workspaceId: newValue, trafficSourceId: null, fallbackCampaignId: null, offers: []})
										}}
										disabled={fieldsDisabled}
										sx={{flex: 1}}
										size="small"
									/>
									<AsyncAutoCompleteFormik
										entityName='campaign'
										limit={15}
										getItems={fetchCampaigns}
										label="Fallback Campaign"
										name="fallbackCampaignId"
										missingDependency={!values.workspaceId}
										where={{campaign: {workspaceId: values.workspaceId}}}
										disabled={fieldsDisabled || !values.workspaceId}
										sx={{flex: 1}}
										size="small"
									/>
									<Box sx={{display: 'flex', gap: 2}}>
										<AsyncAutoCompleteFormik
											entityName='trafficSource'
											limit={15}
											getItems={fetchTrafficSources}
											label="Traffic Source"
											name="trafficSourceId"
											missingDependency={!values.workspaceId}
											where={{trafficSource: {workspaceId: values.workspaceId}}}
											disabled={fieldsDisabled || !values.workspaceId}
											sx={{flex: 1}}
											size="small"
										/>
										<Field
											component={FormikTextField}
											name="externalId"
											type="text"
											label="External Id"
											disabled={fieldsDisabled}
											size="small"
										/>
									</Box>
									<Field
										component={FormikTextField}
										name="comments"
										type="text"
										label="Comments"
										multiline
										disabled={fieldsDisabled}
										size="small"
									/>
									<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
										<Box sx={{fontWeight: 'bold', fontSize: 20}}>Offers</Box>
										<Box sx={{fontWeight: 'bold', fontSize: 16}}>{activeOffers.length} active offers | {formatNum(activeOffers.reduce((agg, off) => agg + (off.trafficCapLimit || 0), 0))} total cap</Box>
										<Flex gap={0}>
											<TooltipButton
												title="Auto Adjust Weights"
												icon={AutoFixHighIcon}
												size="large"
												color="primary"
												onClick={() => {
													setFieldValue('offers', values.offers.map(o => ({...o, weight: Math.round((o.trafficCapLimit / 1000))})))
												}}
											/>
											<TooltipButton
												title="Add Offer"
												icon={AddIcon}
												size="large"
												color="primary"
												onClick={() => setFieldValue('offers', [...values.offers, {...defaultOffer}])}
											/>
										</Flex>
									</Box>
									<Box>
										{values.offers?.map((offer, index) => (
											<Box key={index} sx={{
												display: 'flex', alignItems: 'flex-start', gap: 2,
												p: 1,
												...(campaignModal.selectedOfferId &&  offer?.id ===  campaignModal.selectedOfferId ? {bgcolor: '#f4f4f4'} : {}),
											}}>
												<Box>
													<TooltipButton
														title={`${offer.id ? 'Edit' : 'Create'} offer`}
														icon={offer.id ? EditIcon : AddIcon}
														size="small"
														disabled={fieldsDisabled}
														onClick={() => {
															const enabled = !offer.id;
															setCreateOfferCtx({enabled, index, promptEnabled: false})
															setTimeout(() => setOfferModal({id: offer.id, open: true, reason: 'Update'}), 50)
															
														}}
													/>
													{<TooltipButton
														title="Duplicate offer"
														icon={ContentCopyIcon}
														size="small"
														disabled={fieldsDisabled}
														onClick={() => {
															setCreateOfferCtx({enabled: true, index: values.offers.length, promptEnabled: false})
															setTimeout(() => setOfferModal({id: offer.id, open: true, reason: 'Duplicate'}), 50)
															
														}}
													/>}
													<TooltipButton
														title="Delete offer"
														icon={DeleteIcon}
														size="small"
														color="error"
														disabled={fieldsDisabled}
														onClick={() => setFieldValue('offers', values.offers.filter(a => a !== offer))}
													/>
												</Box>
												<AsyncAutoCompleteFormik
													entityName='offer'
													limit={15}
													getItems={fetchOffers}
													missingDependency={!values.workspaceId || !values.geo}
													where={{offer: {geo: values.geo, workspaceId: values.workspaceId}}}
													label="Offer"
													name={`offers.${index}.id`}
													onChange={(val, option) => {
														if (val) {
															setFieldValue(`offers.${index}.trafficCapLimit`, option?.trafficCapLimit)
														}
													}}
													size="small"
													disabled={fieldsDisabled || !values.workspaceId}
													sx={{flex: 1, bgcolor: 'white'}}
												/>
												<Field
													component={FormikTextField}
													type="number"
													label="Weight"
													name={`offers.${index}.weight`}
													disabled={fieldsDisabled}
													InputProps={{inputProps: {min: 0}}}
													sxWrap={{width: 80, bgcolor: 'white'}}
													size="small"
												/>
												<Box sx={{height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>{((offer.weight / offersTotal) * 100).toFixed(0)}%</Box>
											</Box>
										))
										}
									</Box>
									{submitError && <Alert severity="error">{submitError}</Alert>}
								</Box>
							</DialogContent>
							<DialogActions>
								<Button onClick={closeModal}>Cancel</Button>
								<ProgressButton
									variant="contained"
									color="primary"
									sxWrap={{alignSelf: 'flex-start'}}
									onClick={submitForm}
									disabled={fieldsDisabled}
									loading={saveCampaignQuery.isLoading}
								>
									Save
								</ProgressButton>
							</DialogActions>
						</>
					)
				}}
			</Formik>
		</DraggableDialog>
	)
}
