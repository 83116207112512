import axios from 'services/axios';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import queryString from 'query-string';
import { FetchTrafficHourlyPerformancePayload } from './api.interface';
import { endOfDay } from 'date-fns';

export async function fetchTrafficHourlyPerformance({pagination, filter, sortModel, columns}: FetchTrafficHourlyPerformancePayload) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {
		columns,
		query: {trafficHourlyPerformance: {...filter.trafficHourlyPerformance}},
	};

	['workspaceId', 'trafficSourceId', 'campaignId', 'offerId', 'siteId', 'networkId'].forEach(key => {
		if (!_.isEmpty(filter[key])) {
			payload.query.trafficHourlyPerformance[key] = {op: 'in', value: filter[key]};
		}
	});

	const orderBy = sortModel?.length ? sortModel[0].field : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		fromDate: `${toUTC(filter.dateRange[0]).slice(0, 10)}T00:00:00Z`,
		toDate: `${toUTC(filter.dateRange[1]).slice(0, 10)}T23:59:59.999Z`,
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/trafficHourlyPerformance/query?${queryStr}`;
	const tdpResp = await axios.post(url, payload);

	return tdpResp.data;
}

export async function fetchDailyPerWorkspace({filter}: FetchTrafficHourlyPerformancePayload) {

	const query = {
		fromDate: toUTC(filter.dateRange[0]).slice(0, 10),
		toDate: toUTC(filter.dateRange[1]).slice(0, 10),
	}
	const queryStr = queryString.stringify(query);
	const url = `/dailyPerWorkspace?${queryStr}`;
	const tdpResp = await axios.get(url);

	return tdpResp.data.items;
}

export async function fetchWorkspaceOverview({filter}) {

	const query = {
		fromDate: toUTC(filter.dateRange[0]),
		toDate: toUTC(endOfDay(filter.dateRange[1])),
	}
	const queryStr = queryString.stringify(query);
	const url = `/dailyAggregatedWorkspacePerformance?${queryStr}`;
	const tdpResp = await axios.get(url);

	return tdpResp.data.items;
}

export async function fetchCampaignPerformance({pagination, filter, sortModel, columns}: FetchTrafficHourlyPerformancePayload) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {
		columns,
		query: {trafficHourlyPerformance: {...filter.trafficHourlyPerformance}},
	};

	['workspaceId', 'trafficSourceId', 'campaignId', 'offerId', 'siteId', 'networkId'].forEach(key => {
		if (!_.isEmpty(filter[key])) {
			payload.query.trafficHourlyPerformance[key] = {op: 'in', value: filter[key]};
		}
	});

	const orderBy = sortModel?.length ? sortModel[0].field : '';
	const order = sortModel?.length ? sortModel[0].sort : '';
	const query = {
		fromDate: `${toUTC(filter.dateRange[0]).slice(0, 10)}T00:00:00Z`,
		toDate: `${toUTC(filter.dateRange[1]).slice(0, 10)}T23:59:59.999Z`,
		limit: pageSize,
		offset,
		orderBy,
		order,
	}
	const queryStr = queryString.stringify(query);
	const url = `/trafficHourlyPerformance/byCampaign/query?${queryStr}`;
	const tdpResp = await axios.post(url, payload);

	return tdpResp.data;
}