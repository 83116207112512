import {Tooltip, IconButton, Paper, Box, Typography} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Workspaces as WorkspacesIcon,
} from '@mui/icons-material';
import _ from 'lodash';

// components
import PageTitle from "../../components/PageTitle";
import { useAvailableWindowHeight, useModalState, useQueryableFilter } from "utils/hooks";
import config from "config/config";
import { useWrappedQuery } from "utils/reactQueryHooks";
import { fetchWorkspaces } from "modules/workspaces/api";
import { EnhancedDataGrid } from "../../components/DataGrid/EnhancedDataGrid";
import { Workspace } from "modules/workspaces/api.interface";
import { GridColDef } from "@mui/x-data-grid-pro";

function getColumns({renderActionsColumn}): GridColDef[] {
	return [
		{ field: 'name', headerName: 'Name', filterable: false, flex: 1},
		{ field: 'order', headerName: 'Order', filterable: false, flex: 1},
		{ field: 'color', headerName: 'Color', filterable: false, flex: 1, renderCell: ({row}) => <Box sx={{color: row.color}}>{row.color}</Box>},
		{ field: 'globalCampaign.id', headerName: 'Global Camapign', minWidth: 100, flex: 1, valueGetter: (_, row) => row.globalCampaign?.name},
		{ field: 'actions', headerName: 'Actions', sortable: false, filterable: false, renderCell: renderActionsColumn, width: 120},
	]
}

function ActionsColumn({row, onEditClick}) {
	return (
		<>
			<Tooltip arrow title="Edit Workspace">
				<IconButton
					aria-label="Edit Workspace"
					sx={{p: 0.5}}
					onClick={() => onEditClick(row)}
					size="medium">
					<EditIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</>
	)
}

const defaultFilter = {text: ''};
const defaultSortModel = [{field: 'updatedAt', sort: 'desc'}]
export function WorkspacesPage() {
	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: config.paginationDefaultValue, defaultSortModel});
	const gridHeight = useAvailableWindowHeight()
	const {setModal: setWorkspaceModal} = useModalState('workspace')

	const {isLoading: isWorkspacesLoading, data: workspacesResponse} = useWrappedQuery({
		queryKey: ['workspacesList', filter, pagination, sortModel],
		queryFn: () => fetchWorkspaces({
			pagination,
			sortModel: sortModel,
			filter: {text: filter.text},
		}),
	});
	    
	function openWorkspaceModal(id?: string, reason?: string) {
		setWorkspaceModal({id, open: true, reason})
	}

	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onEditClick={() => openWorkspaceModal(params.row.id, 'Update')}
			/>
		);
	}

	function handleDoubleCellClick(params) {
		if (params.field !== 'actions') {
			openWorkspaceModal(params.row.id, 'Update')
		}
	}

	return (
		<>
			<PageTitle title="Workspaces" icon={WorkspacesIcon} />
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Paper sx={{height: gridHeight}}>
					<EnhancedDataGrid<Workspace>
						items={workspacesResponse?.items}
						density='compact'
						loading={isWorkspacesLoading}
						columns={getColumns({renderActionsColumn})}
						rowCount={workspacesResponse?._meta?.total}
						pagination
						paginationMode="server"
						paginationModel={pagination}
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						editMode='cell'
						onProcessRowUpdateError={console.log}
						pluralName="Workspaces"
						filter={filter}
						setFilter={setFilter}
						showHiddenToggle
						// actionSubmitted={workspaceJustSubmitted}
						// actionSubmitting={workspaceSubmitting}
						actions={[{
							icon: AddIcon,
							primary: true,
							hint: 'Add new workspace',
							onClick: () => openWorkspaceModal(),
						}]}
						onCellDoubleClick={handleDoubleCellClick}
					/>
				</Paper>
			</Box>
		</>
	)
}
