import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {DataGridPro, DataGridProProps, GridOverlay, GridRowSelectionModel, GridRowsProp, GridValidRowModel, GridColDef} from '@mui/x-data-grid-pro';

// components
import GridToolBar from 'components/GridToolBar/GridToolBar';
import config from 'config/config';
import { EmptyState } from "components/EmptyState";
import { gridClasses } from '@mui/x-data-grid';

export type EnhancedColGridDef<T extends GridValidRowModel = any> = GridColDef<T> & {skipDownload?: boolean, formatDownload?: boolean}
export interface EnhancedDataGridProps<T extends GridValidRowModel> extends Omit<DataGridProProps<T>, 'rows'>, React.RefAttributes<HTMLDivElement> {
	items?: GridRowsProp<T>
	pluralName: string,
	actions?: any[],
	columns: EnhancedColGridDef<T>[],
	filters?: JSX.Element,
	selectionActions?: any[],
	filter?: any,
	setFilter?: (filter: any) => void,
	showHiddenToggle?: boolean,
	actionSubmitting?: boolean,
	actionSubmitted?: boolean,
	noToolbar?: boolean
	striped?: boolean
	noSearch?: boolean
}
export function EnhancedDataGrid<T extends GridValidRowModel>(props: EnhancedDataGridProps<T>) {
	const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
	const {
		items, rowCount, pluralName, actions = [], filters, selectionActions = [], filter, setFilter,
		showHiddenToggle, actionSubmitting, actionSubmitted, noToolbar,
		onRowSelectionModelChange, striped, paginationMode, noSearch,
		...restProps
	} = props;
	const actualItems = items || [];

	useEffect(() => onRowSelectionModelChange?.(selectionModel, {} as any), [selectionModel, onRowSelectionModelChange])
	return (
		<DataGridPro
			slots={{
				toolbar: (noToolbar && !selectionModel.length) ? undefined : GridToolBar,
				noRowsOverlay: () => <EmptyState size="medium" isEmpty text={`${pluralName} Not Found`} sx={{container: {height: '100%'}}} />,
				noResultsOverlay: () => <GridOverlay>No results. Try changing the filter</GridOverlay>,
			}}
			rows={actualItems}
			disableColumnMenu
			disableRowSelectionOnClick
			pageSizeOptions={config.paginationOptions}
			rowSelectionModel={selectionModel}
			onRowSelectionModelChange={setSelectionModel}
			paginationMode={paginationMode}
			{...restProps}
			slotProps={{
				...props.slotProps,
				toolbar: {
					noSearch,
					searchValue: filter?.text,
					onSearchChange: (event) => setFilter?.({...filter, text: event.target.value.trimStart()}),
					clearSearch: () => setFilter?.({...filter, text: ''}),
					actions,
					selectionActions,
					selectionModel,
					items: actualItems,
					pluralName,
					actionSubmitting,
					actionSubmitted,
					hiddenToggle: {
						show: showHiddenToggle && false, // DISABLE FOR NOW,
						value: filter?.visibility,
						onToggle: (visibility) => setFilter?.({...filter, visibility}),
					},
					filters,
				},
			}}
			sx={{
				...(striped ? {
					[`& .${gridClasses.row}.even:not(:hover):not(.Mui-hovered)`]: {
						backgroundColor: 'grey.100',
					},
					[`& .${gridClasses.row}:hover`]: {
						backgroundColor: 'grey.300',
					},
				} : {}),
				...restProps.sx,
			}}
			rowCount={paginationMode === 'client' ? undefined : rowCount || 0}
		/>
	)
}
