import { IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";

export default function TooltipButton({icon: Icon, title, iconProps = {}, loading = false, loaderProps = {}, tooltipProps = {}, ...props}) {
	let button = (
		<IconButton {...props} size="small">
			{loading ? (<CircularProgress {...loaderProps} size={20} />) : <Icon {...iconProps} />}
		</IconButton>
	)
	
	if (props.to) {
		button = (
			<Link to={props.to}>
				{button}
			</Link>
			
		)
	}
	return (
		<Tooltip title={title} slotProps={{popper: {sx: {zIndex: 2500}}}} {...tooltipProps}>
			{button}
		</Tooltip>
	)
}
