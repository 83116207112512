import _ from 'lodash';
import { ReportProps } from "./api.interface";
import { toUTC } from 'utils/transformUtils';
import queryString from 'query-string';
import axios from 'services/axios';
import { differenceInDays, endOfDay, startOfDay, subDays } from 'date-fns';

const defaultColumns = ['visits', 'defaultConversions', 'revenue', 'defaultRevenue', 'cost', 'costSources', 'profit', 'roi', 'cvr', 'rpc', 'rejectionConversions', 'rejectionRevenue', 'mttc']
const columnsPerGroupBy = {
	trafficSource: ['trafficSource', ...defaultColumns],
	campaign: ['campaign', 'campaignGeo', 'trafficSources', ...defaultColumns],
	offer: ['offer', 'offerName', 'offerStage', 'offerDate', 'offerOrder', 'offerGeo', 'trafficSources', 'offerCurrentCampaigns', 'siteName', 'networkName', ...defaultColumns],
	network: ['network', 'networkName', ...defaultColumns],
	site: ['site', 'siteName', ...defaultColumns],
	geo: ['geo', ...defaultColumns],
}

export function getGroupColumns(group: string) {
	return columnsPerGroupBy[group];
}
export function getGroupByKey(itm, groupBy) {
	if (groupBy === 'geo') {return itm.geo}
	return itm[groupBy].id;
}

export async function fetchReport({pagination = undefined, filter, sortModel = undefined, workspaceIds}: ReportProps) {
	
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const columns = [...getGroupColumns(filter.groupBy1)]
	if (filter.flatten) {
		columns.push('date');
	}
	const payload = {
		columns: columns,
		groupBy1: filter.groupBy1,
		search: filter.search,
		trafficSource: filter.trafficSource,
		sort: sortModel,
	} as any;
	if (filter.filter1Name && filter.filter1Value?.length) {
		payload[filter.filter1Name] = payload[filter.filter1Name] || {};
		payload[filter.filter1Name].id = {op: 'in', value: filter.filter1Value};
	}
	if (workspaceIds?.length) {
		payload.workspaceIds = workspaceIds;
	}
	if (filter.tags?.ids?.length) {
		const op = filter.tags.op === 'or' ? 'arrOverlap' : 'arrIn';
		payload.tags = {op, value: filter.tags.ids}
	}
	if (filter.offerStages?.length) {
		_.set(payload, 'offer.stage', {op: 'in', value: [filter.offerStages]})
	}
	if (filter.geo?.length && ['offer', 'campaign'].includes(filter.groupBy1)) {
		_.set(payload, `${filter.groupBy1}.geo`, {op: 'in', value: filter.geo})
	}
	if (filter.offerDate) {
		_.set(payload, 'offer.date', toUTC(filter.offerDate).slice(0, 10))
	}
	// ['publisherId', 'advertiserId', 'channelId', 'feedId'].forEach(key => {
	// 	if (!_.isEmpty(filter[key])) {
	// 		payload.query.trafficDailyPerformance[key] = {op: 'in', value: filter[key]};
	// 	}
	// });

	// ['publisher', 'channel', 'advertiser', 'feed'].forEach(entity => {
	// 	const entityTagIds = filter[`${entity}TagIds`];
	// 	if (!_.isEmpty(entityTagIds)) {
	// 		_.set(payload.query, `${entity}.tagIds`, {op: 'arrOverlap', value: entityTagIds})
	// 	}
	// })

	const query = {
		limit: pageSize,
		offset,
		fromDate: toUTC(startOfDay(filter.dateRange[0]), filter.timezone),
		toDate: toUTC(endOfDay(filter.dateRange[1]), filter.timezone),
	}
	const url = `/report/query?${queryString.stringify(query)}`;
	const promises = [axios.post(url, payload)]
	
	if (filter.comparePrevious) {
		const previousEndDate = subDays(new Date(filter.dateRange[0]), 1);
		const rangeDays = differenceInDays(new Date(filter.dateRange[1]), new Date(filter.dateRange[0]));
		const previousStartDate = subDays(previousEndDate, rangeDays);
		const compareQuery = queryString.stringify({
			...query,
			fromDate: `${toUTC(previousStartDate).slice(0, 10)}T00:00:00Z`,
			toDate: `${toUTC(previousEndDate).slice(0, 10)}T23:59:59.999Z`,
		});
		promises.push(axios.post(`/report/query?${compareQuery}`, payload))
	}
	
	const [tdpResp, compareResp] = await Promise.all(promises);
	
	if (compareResp) {
		const compareMap = _.keyBy(compareResp.data.items, itm => getGroupByKey(itm, filter.groupBy1))
		return {
			...tdpResp.data,
			items: tdpResp.data.items.map(itm => ({...itm, previous: compareMap[getGroupByKey(itm, filter.groupBy1)]})),
		}
	}

	return tdpResp.data;
}